const RouteConstants = {
  LANDING_PAGE: "/",
  DISCOVER_PAGE: "/discover",
  NEW_PAIR_PAGE: "/new-pairs",
  TERMSANDCONDITION_PAGE: "/terms-and-condition",
};

export const ListTabObj = {
  allAlerts: {
    key: "allAlerts",
    value: "All Alerts",
  },
  devLess7: {
    key: "devLess7",
    value: "Dev < 7%",
  },
  socialWeb: {
    key: "socialWeb",
    value: "Social & Web",
  },
  devLess7SocialWeb: {
    key: "devLess7SocialWeb",
    value: "Dev < 7% | Social & Web",
  },
  dexPaid: {
    key: "dexPaid",
    value: "Dex Paid",
  },
  koth: {
    key: "koth",
    value: "King of the Hill",
  },
  // movingToRaydium: {
  //   key: "movingToRaydium",
  //   value: "Moving to Raydium",
  // },
  // raydium: {
  //   key: "raydium",
  //   value: "Raydium",
  // },
  // sixtyMinuteTrending: {
  //   key: "sixtyMinuteTrending",
  //   value: "60 Minute Trending",
  // },
  // fifteenMinuteTrending: {
  //   key: "fifteenMinuteTrending",
  //   value: "15 Minute Trending",
  // },
  // fiveMinuteTrending: {
  //   key: "fiveMinuteTrending",
  //   value: "5 Minute Trending",
  // },
};

export const MoonshotListTabObj = {
  allAlerts: {
    key: "allAlerts",
    value: "All Alerts",
  },
  devLess7: {
    key: "devLess7",
    value: "Dev < 7%",
  },
  socialWeb: {
    key: "socialWeb",
    value: "Social & Web",
  },
  devLess7SocialWeb: {
    key: "devLess7SocialWeb",
    value: "Dev < 7% | Social & Web",
  },
};

export const RaydiumListTabObj = {
  allAlerts: {
    key: "allAlerts",
    value: "All Alerts",
  },
};

export const ViewTypeObj = {
  listView: "listView",
  gridView: "gridView",
};

export default RouteConstants;
