const Icons = {
  Affiliate: () => (
    <path d="M6.04765 6.66243C6.2312 6.86593 6.49108 6.98178 6.76348 6.98375C7.03592 6.98472 7.29673 6.87178 7.4832 6.67023L8.75644 5.40255C8.90038 5.25651 9.09649 5.17472 9.30032 5.17472C9.50416 5.17472 9.70027 5.25651 9.8442 5.40255L12.3134 7.89121H12.3144C12.8418 8.41893 13.0437 9.19396 12.839 9.91348H15V3.50391C14.1431 3.53215 13.1626 3.57499 12.5762 2.92071C12.3695 2.75324 11.7502 1.94901 11.5116 2.00255H9.37375C9.14962 1.98113 8.91971 2.33067 8.7719 2.45335L6.02456 5.22232V5.22329C5.82555 5.41023 5.71542 5.67409 5.71928 5.94767C5.72411 6.22225 5.84286 6.48132 6.04765 6.66243ZM4.92124 4.13475L6.3645 2.6879H3.66242C3.46148 2.6879 3.20742 2.96831 2.96783 3.22437C2.51669 3.86892 1.78345 4.25449 1 4.25838V9.91345C1.80086 9.90956 2.84514 9.80538 3.43057 10.4811L6.80409 13.8812C6.87462 13.9581 6.97508 14.0019 7.07942 13.9999C7.18375 13.999 7.28326 13.9522 7.35186 13.8734C7.62236 13.5725 10.2308 10.7704 11.2334 9.68864C11.4169 9.49293 11.413 9.18721 11.2256 8.99638L9.29642 7.052L8.56321 7.78322V7.78224C8.08983 8.27395 7.43776 8.54947 6.75766 8.54655C6.07853 8.54363 5.42935 8.26226 4.95889 7.76666H4.95116C4.46137 7.29737 4.18122 6.64794 4.1735 5.96638C4.16579 5.28482 4.43338 4.62955 4.91253 4.14954C4.9135 4.1437 4.9164 4.13883 4.92026 4.13493L4.92124 4.13475Z" />
  ),
  Arrow: () => <path d="M8 10.4c-.2 0-.4-.1-.5-.2L3.9 6.7 5 5.6l3 3 3-3 1.1 1.1-3.5 3.5c-.2.1-.4.2-.6.2z" />,
  ArrowLeft: () => (
    <path d="M3.2 8.3c0-.1-.1-.2-.1-.3 0-.1 0-.2.1-.3 0-.1.1-.1.1-.2L8 2.9 9.1 4 5.7 7.3h7.2v1.5H5.7L9.1 12 8 13.1 3.4 8.6c-.1-.2-.2-.2-.2-.3z" />
  ),
  ArrowLeft2: () => <path d="m10.6 0 2.1 2.1L6.8 8l5.9 5.9-2.1 2.1-7-7c-.2-.2-.3-.6-.3-1s.2-.8.4-1l6.9-7z" />,
  ArrowRight: () => (
    <path d="M12.7 8.3c0-.1.1-.2.1-.3 0-.1 0-.2-.1-.3 0-.1-.1-.1-.1-.2L8 2.9 6.9 4l3.3 3.3h-7v1.5h7L6.9 12 8 13.1l4.5-4.5c.1-.2.2-.2.2-.3z" />
  ),
  ArrowRight2: () => <path d="m5.4 16-2.1-2.1L9.2 8 3.3 2.1 5.4 0l7 7c.3.3.4.7.4 1 0 .4-.2.8-.4 1l-7 7z" />,
  Baccarat: () => (
    <path d="M3 1v14h10V1H3zm2.5 3.5c-.5 0-.8-.4-.8-.9s.4-.9.8-.9.8.4.8.9-.3.9-.8.9zM6.3 8 8 6.2 9.7 8 8 9.8 6.3 8zm4.2 5.2c-.5 0-.8-.4-.8-.9s.4-.9.8-.9.8.4.8.9-.3.9-.8.9z" />
  ),
  Bet: () => (
    <path d="m8 6.7 3-3c.8-.8.8-2.2 0-3-.8-.8-2.2-.8-3 0-.8-.8-2.2-.8-3 0-.8.8-.8 2.2 0 3l3 3zM3.7 5c-.8-.8-2.2-.8-3 0-.8.8-.8 2.2 0 3-.8.8-.8 2.2 0 3 .8.8 2.2.8 3 0l3-3-3-3zM8 9.3l-3 3c-.8.8-.8 2.2 0 3 .8.8 2.2.8 3 0 .8.8 2.2.8 3 0 .8-.8.8-2.2 0-3l-3-3zM15.4 5c-.8-.8-2.2-.8-3 0l-3 3 3 3c.8.8 2.2.8 3 0 .8-.8.8-2.2 0-3 .8-.8.8-2.2 0-3z" />
  ),
  Bet2: () => (
    <path d="M14 0H2C.9 0 0 .9 0 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2ZM3.7 13.3l-2-2 1-1 1 .9 2.5-2.5 1 1-3.6 3.5Zm0-6-2-2 1-1 1 .9 2.5-2.5 1 1-3.6 3.5Zm9.5 4.4H8.8v-1.4h4.4v1.4Zm0-6H8.8V4.3h4.4v1.4Z" />
  ),
  Betslip: () => <path d="M3 1v14h1.7l1.7-1.8L8 15l1.7-1.8 1.7 1.8H13V1H3zm7.5 8.8h-5V8h5v1.8zm0-3.6h-5V4.5h5v1.7z" />,
  Blog: () => (
    <path d="M1.4,1.4v13.2h13.2V1.4H1.4ZM3.9,3.9h5v5H3.9V3.9ZM12.1,12.1H3.9v-1.7h8.3v1.7ZM12.1,8.8h-1.7V3.9h1.7v5Z" />
  ),
  Browse: () => (
    <path d="m12.6 11.5 3.4 3.4-1.1 1.1-3.4-3.4c-1.3 1-2.8 1.6-4.4 1.6C3.2 14.2 0 11 0 7.1 0 3.2 3.2 0 7.1 0c3.9 0 7.1 3.2 7.1 7.1 0 1.6-.6 3.2-1.6 4.4z" />
  ),
  Browse2: () => (
    <path d="M2 5.4v-.8H0v2.3h2.2c-.1-.5-.2-1-.2-1.5ZM4.3 0H0v2.3h2.7c.4-.9.9-1.7 1.6-2.3ZM3 9.1H0v2.3h5.2C4.3 10.8 3.6 10 3 9.1ZM14.9 13.8l-.2-.1H0V16h16v-2.3h-.9l-.2.1zM9.3 10.7c1.2 0 2.3-.4 3.2-1.1l2.4 2.4 1-1-2.4-2.4c.7-.9 1.1-2 1.1-3.2 0-2.9-2.4-5.3-5.3-5.3S4 2.4 4 5.3s2.4 5.4 5.3 5.4Zm0-9.1c2.1 0 3.7 1.6 3.7 3.6s-1.7 3.7-3.7 3.7-3.6-1.7-3.6-3.7 1.6-3.6 3.6-3.6Z" />
  ),
  Bullet: () => (
    <>
      <circle cx="5" cy="5" r="3" />
      <circle cx="11" cy="11" r="3" />
    </>
  ),
  Casino: () => (
    <path d="M8 2 2.8 7.2c-.5.5-.8 1.2-.8 1.9v.1c0 1.4 1.2 2.6 2.6 2.6.5 0 1-.2 1.4-.4l1.3-.9-.8 2.8v.7h3v-.8l-.8-2.8 1.3.9c.4.3.9.4 1.4.4 1.4 0 2.6-1.2 2.6-2.6 0-.7-.3-1.4-.8-1.9L8 2z" />
  ),
  Casino2: () => (
    <path d="M2.3 0v16h11.4V0H2.3zm2.8 4c-.5 0-.9-.4-1-1 0-.6.4-1 1-1s1 .4 1 1-.5 1-1 1zm1 4L8 6l1.9 2L8 10 6.1 8zm4.7 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z" />
  ),
  Casino3: () => (
    <path d="M12 4c0 .3 0 .5-.1.8h.1c2.2 0 4 1.8 4 4s-1.8 4-4 4c-1.3 0-2.4-.6-3.1-1.5L10 15v1H6v-1l1.1-3.8c-.7.9-1.9 1.5-3.1 1.5-2.2 0-4-1.8-4-4s1.8-4 4-4h.1C4 4.5 4 4.3 4 4c0-2.2 1.8-4 4-4s4 1.8 4 4z" />
  ),
  Challenges: () => (
    <>
      <path d="M4.25 2L6.93934 4.68934L4.68934 6.93934L2 4.25V2H4.25Z" />
      <path d="M7.03033 11.2197L8.15533 12.3447L7.09467 13.4053L5.375 11.6857L4.24829 12.8124C4.24942 12.8331 4.25 12.854 4.25 12.875C4.25 13.4963 3.74632 14 3.125 14C2.50368 14 2 13.4963 2 12.875C2 12.2537 2.50368 11.75 3.125 11.75C3.14602 11.75 3.1669 11.7506 3.18763 11.7517L4.31434 10.625L2.59467 8.90533L3.65533 7.84467L4.78033 8.96967L11.75 2H14V4.25L7.03033 11.2197Z" />
      <path d="M12.875 14C12.2537 14 11.75 13.4963 11.75 12.875C11.75 12.854 11.7506 12.8331 11.7517 12.8124L9.56434 10.625L12.3447 7.84467L13.4053 8.90533L11.6857 10.625L12.8124 11.7517C12.8331 11.7506 12.854 11.75 12.875 11.75C13.4963 11.75 14 12.2537 14 12.875C14 13.4963 13.4963 14 12.875 14Z" />
    </>
  ),
  Chat: () => (
    <path d="M.8 16c-.1 0-.2 0-.3-.1-.3-.1-.5-.4-.5-.7V2.4C0 1.1 1.1 0 2.4 0h11.2C14.9 0 16 1.1 16 2.4v8c0 1.3-1.1 2.4-2.4 2.4H4.3l-3 3c-.1.1-.3.2-.5.2zM2.4 1.6c-.4 0-.8.4-.8.8v10.9l1.8-1.8c.1-.1.4-.2.6-.2h9.6c.4 0 .8-.4.8-.8v-8c0-.4-.4-.8-.8-.8H2.4z" />
  ),
  Chat2: () => (
    <path d="M.5 1v11.2h4.7L8 15l2.8-2.8h4.7V1H.5zm4.7 6.5H3.3V5.7h1.9v1.8zm3.7 0H7.1V5.7H9v1.8zm3.8 0h-1.9V5.7h1.9v1.8z" />
  ),
  ChatFilled: () => (
    <path d="M.8 16c-.1 0-.2 0-.3-.1-.3-.1-.5-.4-.5-.7V2.4C0 1.1 1.1 0 2.4 0h11.2C14.9 0 16 1.1 16 2.4v8c0 1.3-1.1 2.4-2.4 2.4H4.3l-3 3c-.1.1-.3.2-.5.2z" />
  ),
  ChatFilled2: () => (
    <path d="M8 .9C3.6.9 0 3.6 0 7s1.2 3.7 3 4.8c-.4 1.2-1.1 2.4-2 3.3 1.9 0 3.6-.9 4.7-2.3.7.2 1.5.3 2.3.3 4.4 0 8-2.7 8-6.1S12.4.9 8 .9ZM4.4 8c-.5 0-.9-.4-.9-.9s.4-.9.9-.9.9.4.9.9-.4.9-.9.9ZM8 8c-.5 0-.9-.4-.9-.9s.4-.9.9-.9.9.4.9.9-.4.9-.9.9Zm3.6 0c-.5 0-.9-.4-.9-.9s.4-.9.9-.9.9.4.9.9-.4.9-.9.9Z" />
  ),
  Check: () => (
    <path d="M8 1.3a6.7 6.7 0 1 0 0 13.4A6.7 6.7 0 0 0 8 1.3Zm-.7 9.4L4.5 7.9l.9-.9 1.9 1.9 3.8-3.8.9.9-4.7 4.7Z" />
  ),
  Close: () => <path d="M13 4.3 11.7 3 8 6.7 4.3 3 3 4.3 6.7 8 3 11.7 4.3 13 8 9.3l3.7 3.7 1.3-1.3L9.3 8 13 4.3z" />,
  Collapse: () => (
    <>
      <path d="M14 7.4H8.3l2.6-2.6-1-1-3.7 3.7c-.2.3-.2.7 0 1l3.7 3.7.9-.9-2.5-2.7H14V7.4z" />
      <path d="M3.5 12.8c-.1-.1-.2-.3-.2-.4V3.6c0-.2.1-.3.2-.4.1-.2.3-.2.5-.2h3.4V1.7H4c-.5 0-1 .2-1.4.5-.4.4-.6.9-.6 1.4v8.8c0 .5.2 1 .6 1.4.4.3.9.5 1.4.5h3.4V13H4c-.2 0-.4 0-.5-.2z" />
    </>
  ),
  Copy: () => (
    <>
      <path d="M6.7 6.6V4.7H4.1c-.8 0-1.5.3-2 .7-.4.3-.8 1-.8 2.1v4.4c0 1.1.4 1.7.8 2.1.5.5 1.2.7 2 .7H8c.8 0 1.5-.3 2-.7.4-.3.8-1 .8-2.1V8.8H8.9c-1.2 0-2.2-1-2.2-2.2Z" />
      <path d="M13.9 2.1c-.5-.5-1.2-.7-2-.7H8c-.8 0-1.4.2-1.9.7-.4.3-.7.8-.8 1.4v.6h4c.8 0 1.5.3 2 .7.5.5.8 1.2.8 2.1v4.6h.6c.5-.3.9-.5 1.3-.8.4-.3.8-1 .8-2V4.3c0-1.1-.4-1.7-.8-2.1Z" />
      <path d="m8.3 5.1-.9-.9v2.5C7.4 7.4 8 8 8.8 8h2.5l-.9-.9c-.6-.6-1.5-1.5-2.1-2.2Z" />
    </>
  ),
  Crypto: () => (
    <>
      <path d="M2 2v12h12v-1.7H3.7V2H2z" />
      <path d="m5.4 7.1 1.7-1.7L9.7 8 14 3.7v6.9H5.4V7.1z" />
    </>
  ),
  Dashboard: () => (
    <>
      <path d="M14 2H2v5.1h2l1.3-2.6h1.9l2.5 4.9L10.9 7H14V2z" />
      <path d="M14 8.9h-2l-1.3 2.6H8.8l-2.5-5-1.2 2.4H2V14h12V8.9z" />
    </>
  ),
  EnhanceDrop: () => (
    <path d="M1 6.09091L2.90909 4.18182L13.7273 1L15 2.27273L11.8182 13.0909L9.90909 15L6.72727 10.5455L9.90909 7.36364L8.63636 6.09091L5.45455 9.27273L1 6.09091Z" />
  ),
  Exclusive: () => (
    <path d="M3,1v14h1.7l3.3-3.5,3.3,3.5h1.7V1H3ZM10.1,8.1l-.6.4-1.5-1-1.5,1-.6-.4.6-1.7-1.5-1,.2-.7h1.8l.6-1.7h.8l.6,1.7h1.8l.2.7-1.5,1,.6,1.7Z" />
  ),
  Explore: () => <path d="M5.5 1h3v14h-3zM.5 3h3v12h-3zM9.84 3.729l2.814-.702 2.806 11.256-2.813.701z" />,
  External: () => <path d="m5.1 11.8-.9-.9 5.5-5.5H5.4l.1-1.2h6.3v6.3h-1.2V6.3l-5.5 5.5z" />,
  Favourites: () => (
    <path d="M7.11589 1.5H8.88413L10.1989 5.45079L14.4536 5.45079L15 7.09273L11.5579 9.53444L12.8727 13.4852L11.4421 14.5L8.00002 12.0583L4.55792 14.5L3.12738 13.4852L4.44214 9.53446L1 7.09273L1.54642 5.45079L5.80112 5.45079L7.11589 1.5Z" />
  ),
  Forum: () => (
    <path d="M1,2v10.4h4.4l2.6,2.6,2.6-2.6h4.4V2H1ZM5.4,8.1h-1.8v-1.7h1.8v1.7ZM8.9,8.1h-1.8v-1.7h1.8v1.7ZM12.4,8.1h-1.8v-1.7h1.8v1.7Z" />
  ),
  Fullscreen: () => (
    <path d="M11.2.8H16v4.8h-1.6V2.4h-3.2V.8zM0 .8h4.8v1.6H1.6v3.2H0V.8zm14.4 12.8v-3.2H16v4.8h-4.8v-1.6h3.2zm-12.8 0h3.2v1.6H0v-4.8h1.6v3.2z" />
  ),
  Future: () => (
    <>
      <circle cx="2.3" cy="2.3" r="2.3" />
      <circle cx="13.7" cy="13.7" r="2.3" />
      <path d="M12.6 4v5.1h2.3V4c0-2.2-1.8-4-4-4s-4 1.8-4 4v8c0 .9-.8 1.7-1.7 1.7s-1.8-.8-1.8-1.7V6.9H1.1V12c0 2.2 1.8 4 4 4s4-1.8 4-4V4c0-.9.8-1.7 1.7-1.7 1 0 1.8.8 1.8 1.7z" />
    </>
  ),
  Game: () => (
    <path d="M14.3,5.3c-.1-1.3-1.3-2.3-2.6-2.3h-7.5c-1.4,0-2.5,1-2.6,2.3l-.7,6.9,2.6.8,2.6-2.5h3.5l2.6,2.5,2.6-.8-.7-6.9ZM5.4,8c-1,0-1.8-.7-1.8-1.7s.8-1.7,1.8-1.7,1.8.7,1.8,1.7-.8,1.7-1.8,1.7ZM10.6,8.8c-.5,0-.9-.4-.9-.8s.4-.8.9-.8.9.4.9.8-.4.8-.9.8ZM11.5,6.3c-.5,0-.9-.4-.9-.8s.4-.8.9-.8.9.4.9.8-.4.8-.9.8Z" />
  ),
  Graph: () => (
    <>
      <path d="M1,1v14h14v-2H3V1H1Z" />
      <path d="M5,7l2-2,3,3,5-5v8H5v-4Z" />
    </>
  ),
  HidePass: () => (
    <path d="m0 0 3.3 4.1c-.1 0-.2.1-.2.2L0 8l3.1 3.7C4.3 13.2 6.1 14 8 14c1 0 2-.2 2.8-.7L13 16h3L3 0H0zm5.4 6.6 3.5 4.3c-.3.1-.6.1-.9.1-1.7 0-3-1.3-3-3 0-.5.1-1 .4-1.4zM16 8l-3.1-3.7C11.7 2.8 9.9 2 8 2h-.4l6.6 8.1L16 8z" />
  ),
  Hidden: () => (
    <path d="m3.1 5.4-.3 1.1H1.3c-.1 0-.2.1-.3.1s-.2.3-.2.4c0 .1.1.3.1.4s.2.1.4.1h13.3c.1 0 .3-.1.4-.1.1-.1.1-.2.1-.4 0-.1-.1-.3-.1-.4-.1-.1-.2-.1-.4-.1h-1.5l-.3-1.1c-.4-1.5-.5-2.2-1.1-2.6-.4-.5-1.2-.5-2.7-.5H7c-1.5 0-2.2 0-2.8.4-.6.5-.8 1.2-1.1 2.7zm1.2 8.3c.5 0 1-.2 1.4-.5.4-.3.7-.8.8-1.3l.7-.4c.2-.1.4-.2.7-.2s.5.1.7.2l.7.4c.1.5.5 1 1 1.3.5.3 1 .4 1.6.4.6-.1 1.1-.3 1.5-.8.4-.4.6-1 .6-1.5 0-.6-.2-1.1-.6-1.6-.4-.4-.9-.6-1.5-.7s-1.1.1-1.6.4c-.5.3-.8.8-.9 1.4l-.3-.1c-.3-.2-.7-.3-1.1-.3s-.8.1-1.1.3l-.3.1c-.1-.6-.5-1-.9-1.4-.5-.3-1.1-.4-1.6-.4-.6.1-1.1.4-1.5.8-.4.4-.6 1-.6 1.6 0 .6.2 1.1.7 1.6.4.4 1 .7 1.6.7z" />
  ),
  Home: () => <path d="M2 6.7v7.8h4.3V11c0-1 .8-1.7 1.7-1.7s1.7.8 1.7 1.7v3.5H14V6.7L8 1.5 2 6.7z" />,
  Info: () => <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 3h2v2H7V3zm3 10H7V8H6V6h3v5h1v2z" />,
  Leaf: () => (
    <path d="M10.5 1.8c-3.9 0-7.1 3.3-7.1 7.4v5h1.4V9.7l3.1-3.5 1 1.2-2.6 3.1v2.1c3.6-.4 6.4-3.5 6.4-7.4V1.8h-2.2z" />
  ),
  LiveCasino: () => (
    <>
      <path d="M2 13L3.55556 13L9 8L3.55555 3L2 3L2 13Z" />
      <path d="M14 13L12.4444 13L7 8L12.4444 3L14 3L14 13Z" />
    </>
  ),
  Live: () => (
    <>
      <circle cx="8" cy="8" r="7" style={{ opacity: 0.1 }} />
      <circle cx="8" cy="8" r="4.7" style={{ opacity: 0.1 }} />
      <circle cx="8" cy="8" r="2.3" />
    </>
  ),
  Lock: () => (
    <path d="M14.6 5.6c-.1-.1-.3-.2-.5-.2h-1.5v-.8c0-1.2-.5-2.4-1.3-3.2C10.4.5 9.2 0 8 0 6.8 0 5.6.5 4.8 1.3s-1.3 2-1.3 3.2v.8H1.9c-.2 0-.4.1-.5.2s-.3.4-.3.6v9.1c0 .2.1.4.2.5.2.2.4.3.6.3h12.2c.2 0 .4-.1.5-.2.1-.1.2-.3.2-.5V6.1c.1-.2 0-.4-.2-.5zM5 4.6c0-.8.3-1.6.8-2.2.6-.6 1.4-.9 2.2-.9.8 0 1.6.3 2.2.9s.8 1.4.8 2.2v.8H5v-.8zm.7 6.8H4.2V9.9h1.5v1.5zm3.1 0H7.2V9.9h1.5v1.5zm3 0h-1.5V9.9h1.5v1.5z" />
  ),
  Unlock: () => (
    <path d="M15 6.6c-.2-.1-.4-.2-.6-.2H4v-.8c0-.9.3-1.8.9-2.5.5-.7 1.3-1.2 2.2-1.4.9-.2 1.8-.1 2.6.3s1.5 1 1.9 1.8l1.4-.7C12.4 2 11.6 1 10.4.5 9.2 0 7.9-.1 6.7.1c-1.2.3-2.3 1-3.1 2-.8 1-1.2 2.2-1.2 3.5v.8h-.8c-.2 0-.4 0-.6.2-.1.2-.2.4-.2.6v8c0 .2 0 .4.2.6.2.1.4.2.6.2h12.8c.2 0 .4-.1.6-.2.1-.2.2-.4.2-.6v-8c0-.2 0-.4-.2-.6zM9.6 12H6.4v-1.6h3.2V12z" />
  ),
  Logout: () => (
    <>
      <path d="M1 1H9V5H4L4 11H9V15H1V1Z" />
      <path d="M11 7V4H12L16 8L12 12H11V9H6V7L11 7Z" />
    </>
  ),
  Madrid: () => (
    <path d="M11.9 6.3 6.8 2 5.7 3.3l1.6 1.4c-.5.4-1.2.6-1.9.6-1.3 0-2.4-.7-3-1.7H1v7h14V7.3l-3.1-1zM1 12.3h14V14H1v-1.7z" />
  ),
  Menu: () => <path d="M1 3h14v2H1V3zM1 7h14v2H1V7zM1 11h14v2H1v-2z" />,
  Notification: () => (
    <>
      <path d="M1,1v6h14V1H1ZM3.7,6l-1.7-1.7.8-.7,1,.9,2.5-2.5.8.7-3.3,3.3Z" />
      <path d="M1,15h14v-6H1v6ZM2.8,11.6l1,.9,2.5-2.5.8.7-3.3,3.3-1.7-1.7.8-.7Z" />
    </>
  ),
  NFT: () => (
    <>
      <path d="M2 4c0-1.1.9-2 2-2h3.5c1.1 0 2 .9 2 2v1H5v4.5H4c-1.1 0-2-.9-2-2V4z" />
      <path d="M14 8.5c0-1.1-.9-2-2-2H6.5V12c0 1.1.9 2 2 2H12c1.1 0 2-.9 2-2V8.5z" />
    </>
  ),
  NoData: () => (
    <>
      <path d="M6.2 7.4H5c-.3 0-.6.3-.6.6s.3.6.6.6h1.2c.3 0 .6-.3.6-.6s-.2-.6-.6-.6zM14.6 4c-.3 0-.6.2-.6.6V14c0 .5-.4.8-.8.8H3.8c-.3 0-.6.3-.6.6s.3.6.6.6h9.4c1.1 0 2-.9 2-2V4.6c-.1-.4-.3-.6-.6-.6z" />
      <path d="M11 11.6c.3 0 .6-.3.6-.6s-.3-.6-.6-.6H8.2c-.3 0-.6.3-.6.6s.3.6.6.6H11zM8 4.4H5c-.3 0-.6.3-.6.6s.3.6.6.6h3c.3 0 .6-.3.6-.6s-.3-.6-.6-.6z" />
      <path d="M15.8.2c-.2-.2-.6-.2-.8 0l-2.1 2.1L10.6.2c-.1-.1-.3-.2-.4-.2H2.8C1.7 0 .8.9.8 2v12.3l-.6.7c-.2.2-.2.6 0 .8.2.2.6.2.8 0L15.8 1c.3-.2.3-.6 0-.8zM2 13.2V2c0-.5.4-.8.8-.8h6.8v2c0 .6.3 1.2.8 1.6L2 13.2zm9.3-9.3c-.3-.1-.5-.4-.5-.7V1.9l1.3 1.2-.8.8z" />
    </>
  ),
  Pin: () => (
    <path d="M10.6 1 8.9 2.8l.9.9L8 5.4 6.9 4.2c-.4-.4-1-.6-1.5-.6s-1.1.2-1.5.6L2.8 5.4l7.9 7.9 1.1-1.1c.4-.4.6-.9.6-1.5s-.2-1.1-.6-1.5L10.6 8l1.8-1.8.9.9L15 5.4 10.6 1zM1 14.1l.9.9 4.7-3.3-2.3-2.3z" />
  ),
  Play: () => (
    <path d="m3 1.4 10.5 6.1c.1 0 .1.1.2.2 0 .1.1.2.1.2 0 .1 0 .2-.1.2s-.1.1-.2.2L3 14.6c-.1 0-.2.1-.3.1-.1 0-.2 0-.3-.1-.1 0-.1-.1-.2-.2 0-.1-.1-.2-.1-.3V1.9c0-.1 0-.2.1-.3 0-.1.1-.1.2-.2.1 0 .2-.1.3-.1.1.1.2.1.3.1z" />
  ),
  Profile: () => (
    <>
      <path d="M8 7C9.65685 7 11 5.65685 11 4C11 2.34315 9.65685 1 8 1C6.34315 1 5 2.34315 5 4C5 5.65685 6.34315 7 8 7Z" />
      <path d="M14 12C14 10.3431 12.6569 9 11 9H5C3.34315 9 2 10.3431 2 12V15H14V12Z" />
    </>
  ),
  Promotions: () => (
    <path d="M15,8c0-1-.6-1.9-1.4-2.3.3-.9,0-1.9-.6-2.6-.7-.7-1.7-.9-2.6-.6-.4-.9-1.3-1.4-2.3-1.4s-1.9.6-2.3,1.4c-.9-.3-1.9,0-2.6.6-.7.7-.9,1.7-.6,2.6-.9.4-1.4,1.3-1.4,2.3s.6,1.9,1.4,2.3c-.3.9,0,1.9.6,2.6s1.7.9,2.6.6c.4.9,1.3,1.4,2.3,1.4s1.9-.6,2.3-1.4c.9.3,1.9,0,2.6-.6.7-.7.9-1.7.6-2.6.9-.4,1.4-1.3,1.4-2.3ZM7.5,7.5h1c.8,0,1.5.7,1.5,1.5s-.7,1.5-1.5,1.5v.5h-1v-.5c-.8,0-1.5-.7-1.5-1.5h1c0,.3.2.5.5.5h1c.3,0,.5-.2.5-.5s-.2-.5-.5-.5h-1c-.8,0-1.5-.7-1.5-1.5s.7-1.5,1.5-1.5v-.5h1v.5c.8,0,1.5.7,1.5,1.5h-1c0-.3-.2-.5-.5-.5h-1c-.3,0-.5.2-.5.5s.2.5.5.5Z" />
  ),
  Providers: () => (
    <>
      <path d="M5.9,1.7h2.5v12.6h-2.5V1.7Z" />
      <path d="M1.7,3.5h2.5v10.8H1.7V3.5Z" />
      <path d="M11.9,3.5l-2.4.6,2.4,10.2,2.4-.6-2.4-10.2Z" />
    </>
  ),
  Originals: () => (
    <path d="M9.7,1h-1.7v4.4c0,.5-.4.9-.9.9s-.9-.4-.9-.9v-1.8h-1.7s-2.6,2.2-2.6,5.2,2.7,6.1,6,6.1,6-2.7,6-6.1c0-5.2-4.3-7.9-4.3-7.9ZM8,13.2c-.9,0-1.7-.8-1.7-1.8,0-2.2,1.7-3.5,1.7-3.5,0,0,1.7,1.3,1.7,3.5s-.8,1.8-1.7,1.8Z" />
  ),
  Recent: () => (
    <path d="M8,1c-3.9,0-7,3.1-7,7s3.1,7,7,7,7-3.1,7-7S11.9,1,8,1ZM10,11.2l-2.9-2.9V3.6h1.8v4l2.4,2.4-1.2,1.2Z" />
  ),
  Redeem: () => (
    <path d="M12 2H4c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-5.3 9.4L4 8.6l1.2-1.2 1.5 1.5 4.1-4.1L12 6l-5.3 5.4z" />
  ),
  Release: () => (
    <path d="M11.2,2.7h0s0,0,0,0h0c0,0,0,0,0,0,.1.2.2.3.1.5h0c0,0,0,.1,0,.1-.7,2-.5,3.7.5,5.6.5,0,1,.3,1.4.7.4.4.7.8.9,1.4.2.5.2,1.1,0,1.6-.1.5-.4,1-.8,1.4-.4.4-.9.6-1.4.7s-1.1,0-1.6-.2c-.5-.2-.9-.6-1.2-1-.3-.4-.5-1-.5-1.5h0c0-.1,0-.3,0-.3,0-.6.2-1.1.5-1.5.3-.4.7-.8,1.2-1-.7-1.5-.9-2.9-.7-4.5-.1,0-.2.1-.4.2h0c-1.6,1-2.5,2.1-2.8,3.3.4.3.8.7,1,1.2.2.5.3,1,.3,1.6,0,.6-.2,1.1-.5,1.6-.3.5-.7.9-1.2,1.1-.5.3-1,.4-1.5.4-.5,0-1.1-.1-1.5-.4-.5-.3-.9-.7-1.2-1.1-.3-.5-.4-1-.5-1.6v-.2s0-.2,0-.2c0-.5.1-.9.3-1.3.2-.4.5-.8.8-1.1.3-.3.8-.5,1.2-.6.4-.1.9-.1,1.3,0,.2-.8.7-1.5,1.3-2.2-1.3,0-2.5-.5-3.8-1.4h-.2c0-.1,0-.2,0-.2h0s0,0,0,0h0s0,0,0,0h0s0,0,0,0h0c0,0,0-.2,0-.2h0c0,0,0-.1,0-.1h0c0,0,0-.2,0-.2h0c0,0,0-.2,0-.2h0s0,0,0,0h0s0,0,0,0h0s0,0,0,0h0s0,0,0,0h0c2.8-2.1,5.8-2.1,8.6,0Z" />
  ),
  Reset: () => (
    <path d="M12 2H2c-.2 0-.3 0-.5.2-.1.1-.2.3-.2.5v2H12V2ZM14.5 6.2c-.1-.1-.3-.2-.5-.2H1.3v7.3c0 .2 0 .3.2.5.1.1.3.2.5.2h12c.2 0 .3 0 .5-.2.1-.1.2-.3.2-.5V6.6c0-.2 0-.3-.2-.5ZM12 10.7h-2V9.4h2v1.3Z" />
  ),
  Rewards: () => (
    <>
      <path d="M3.625 3.8V1H5.375C6.82475 1 8 2.2536 8 3.8C8 2.2536 9.17525 1 10.625 1H12.375V3.8H15V6.6H1V3.8H3.625Z" />
      <path d="M1.875 8.46667H7.125V15H1.875V8.46667Z" />
      <path d="M14.125 8.46667H8.875V15H14.125V8.46667Z" />
    </>
  ),
  Roulette: () => (
    <>
      <circle cx="8" cy="8" r="2.7" />
      <path d="M3.7,6.9l-1.7-1.7c-.4.9-.6,1.8-.6,2.9s.2,2,.6,2.9l1.7-1.7c0-.4-.2-.8-.2-1.1s0-.8.2-1.1Z" />
      <path d="M8,12.4c-.4,0-.8,0-1.1-.2l-1.7,1.7c.9.4,1.8.6,2.9.6s2-.2,2.9-.6l-1.7-1.7c-.4,0-.8.2-1.1.2Z" />
      <path d="M14,5.1l-1.7,1.7c0,.4.2.8.2,1.1s0,.8-.2,1.1l1.7,1.7c.4-.9.6-1.8.6-2.9s-.2-2-.6-2.9Z" />
      <path d="M5.1,2l1.7,1.7c.4,0,.8-.2,1.1-.2s.8,0,1.1.2l1.7-1.7c-.9-.4-1.8-.6-2.9-.6s-2,.2-2.9.6Z" />
    </>
  ),
  Search: () => (
    <path d="M11.9 10.8c.8-1 1.2-2.3 1.2-3.6 0-3.3-2.6-6-6-6-3.3 0-6 2.7-5.9 6.1 0 3.3 2.6 6 6 6 1.4 0 2.6-.5 3.6-1.2l2.6 2.7 1.1-1.2-2.6-2.8zM8.5 6.1c-.7-.7-1.9-.7-2.5 0s-.6 1.7.1 2.4L4.8 9.7c-1.4-1.4-1.4-3.5 0-4.8s3.5-1.4 4.8 0L8.5 6.1z" />
  ),
  SearchLens: () => (
    <path d="m13.8 12.9-2.4-2.4c.7-.9 1.1-2 1.1-3.1 0-2.8-2.3-5.2-5.2-5.2S2.2 4.5 2.2 7.4s2.3 5.2 5.2 5.2c1.2 0 2.3-.4 3.1-1.1l2.4 2.4.9-1zM3.5 7.4c0-2.1 1.7-3.8 3.8-3.8s3.8 1.7 3.8 3.8-1.7 3.8-3.8 3.8-3.8-1.7-3.8-3.8z" />
  ),
  SearchLens2: () => (
    <path d="M12.8 11.4c.9-1.2 1.4-2.7 1.4-4.3C14.2 3.2 11 0 7.1 0S0 3.1 0 7s3.2 7.2 7.1 7.2c1.6 0 3.1-.5 4.3-1.4l3.2 3.2 1.4-1.4-3.2-3.2Zm-5.7.8C4.3 12.2 2 9.8 2 7s2.2-5.1 5.1-5.1 5.2 2.2 5.2 5.1-2.4 5.2-5.2 5.2Z" />
  ),
  Settings: () => (
    <path d="M15,8.9v-1.8l-1.9-.5c-.1-.5-.3-.9-.5-1.3l1-1.7-1.2-1.2-1.7,1c-.4-.2-.8-.4-1.3-.5l-.5-1.9h-1.8l-.5,1.9c-.5.1-.9.3-1.3.5l-1.7-1-1.2,1.2,1,1.7c-.2.4-.4.8-.5,1.3l-1.9.5v1.8l1.9.5c.1.5.3.9.5,1.3l-1,1.7,1.2,1.2,1.7-1c.4.2.8.4,1.3.5l.5,1.9h1.8l.5-1.9c.5-.1.9-.3,1.3-.5l1.7,1,1.2-1.2-1-1.7c.2-.4.4-.8.5-1.3l1.9-.5ZM8,9.8c-1,0-1.8-.8-1.8-1.8s.8-1.8,1.8-1.8,1.8.8,1.8,1.8-.8,1.8-1.8,1.8Z" />
  ),
  Sponshorship: () => (
    <>
      <path d="M2.3,6L.2,7.2l1.1,2c1.1-.7,1.6-2.1.9-3.2Z" />
      <path d="M12.4,2.8l-1.1-2-2.1,1.2c.6,1.1,2.1,1.4,3.2.8Z" />
      <path d="M14.7,6.8c-1.1.7-1.6,2.1-.9,3.2l2.1-1.2-1.1-2Z" />
      <path d="M3.6,13.2l1.1,2,2.1-1.2c-.6-1.1-2.1-1.4-3.2-.8Z" />
      <path d="M13.9,5.5l-.8-1.3c-1.9,1.1-4.3.5-5.4-1.3l-4.1,2.4c1,1.8.4,4.2-1.6,5.3l.8,1.3c1.9-1.1,4.3-.5,5.4,1.3l4.1-2.4c-1-1.8-.4-4.2,1.6-5.3ZM8.8,9.3c-.8.4-1.7.2-2.1-.5-.4-.7-.1-1.7.6-2.1.8-.4,1.7-.2,2.1.5.4.7.1,1.7-.6,2.1Z" />
    </>
  ),
  Sports: () => (
    <path d="M2 8.8c.1 1 .5 2 1.1 2.8 0 0 .1 0 .1-.1 1.1-.6 2.8-1.3 4.8-1.3s3.7.6 4.8 1.2c0 0 .1 0 .1.1.6-.8 1-1.7 1.1-2.8H2zM8 14c-1.4 0-2.8-.5-3.8-1.4.9-.5 2.2-.9 3.8-.9 1.6 0 2.9.4 3.8.9-1 .9-2.4 1.4-3.8 1.4zM4.2 3.4C5.2 2.5 6.6 2 8 2s2.8.5 3.8 1.4c-.9.4-2.2.8-3.8.8-1.6 0-2.9-.4-3.8-.8zM3.1 4.5s.1 0 0 0C4.3 5.1 6 5.8 8 5.8c2 0 3.7-.6 4.8-1.2 0 0 .1 0 .1-.1.6.8 1 1.7 1.1 2.8H2c.1-1.1.5-2 1.1-2.8z" />
  ),
  Soprts2: () => (
    <path d="M8 0c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8zm1.3 11.2H6.7l-1.1 1.5.4 1.4c.6.2 1.3.3 2 .3s1.4-.1 2-.3l.4-1.4-1.1-1.5zM2.6 7.1l-1 .7V8c0 1.4.4 2.7 1.2 3.7h1.5l1.1-1.5-.9-2.5-1.9-.6zm10.8 0-1.9.6-.8 2.5 1.1 1.5h1.5c.8-1.1 1.2-2.4 1.2-3.7v-.2l-1.1-.7zM9.8 1.9l-1 .8v2L11 6.2l1.8-.6.4-1.4c-.8-1.1-2-1.9-3.4-2.3zm-3.6 0c-1.4.4-2.6 1.2-3.4 2.4l.4 1.4 1.8.5 2.2-1.6v-2l-1-.7z" />
  ),
  Slots: () => (
    <>
      <path d="M3.9,6l-3.9,1.1.4,1.6,2-.5c-.6,1.5-.7,3.1-.5,4.4l2.2-.6c-.3-1.4-.3-2.9.1-4.6l-.4-1.3Z" />
      <path d="M12.1,6l-.4,1.6,2,.5c-1.3,1-2.2,2.3-2.6,3.6l2.2.6c.4-1.4,1.2-2.7,2.4-3.9l.4-1.3-3.9-1.1Z" />
      <path d="M11.4,3.5h-6.5v2h4c-1.6,1.9-2.7,4-3.1,6.2h2.6c.4-2.3,1.4-4.5,3.1-6.5v-1.7Z" />
    </>
  ),
  ShowPass: () => (
    <path d="M12.9 4.3C11.7 2.8 9.9 2 8 2s-3.7.8-4.9 2.3L0 8l3.1 3.7C4.3 13.2 6.1 14 8 14s3.7-.8 4.9-2.3L16 8l-3.1-3.7zM8 11c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z" />
  ),
  Star: () => (
    <path d="m8 12.8-4.9 2.8L4.2 10 0 6.2l5.6-.7L8 .4l2.4 5.1 5.6.7-4.2 3.8 1.1 5.6L8 12.8zm0-1.6 3 1.7-.7-3.3 2.5-2.3-3.4-.5L8 3.7 6.6 6.8l-3.4.4 2.5 2.3-.7 3.4 3-1.7z" />
  ),
  StarFilled: () => <path d="m8 12.8-4.9 2.8L4.2 10 0 6.2l5.6-.7L8 .4l2.4 5.1 5.6.7-4.2 3.8 1.1 5.6L8 12.8Z" />,
  Support: () => (
    <path d="M3.71429 7.06667C3.71429 4.67343 5.63307 2.73333 8 2.73333C10.3669 2.73333 12.2857 4.67343 12.2857 7.06667V7.93333H9.71429V14H11.4286C12.8487 14 14 12.8359 14 11.4V7.06667C14 3.71614 11.3137 1 8 1C4.68629 1 2 3.71614 2 7.06667V11.4C2 12.8359 3.15127 14 4.57143 14H6.28571V7.93333H3.71429V7.06667Z" />
  ),
  Swap: () => (
    <>
      <path d="M9.8 8h-.9V5.2l-3.4-.7H5c-1.3 0-2.3 1-2.3 2.3v2.1H1V6.8c0-2.2 1.8-4.1 4.1-4.1.3 0 .5 0 .8.1l3 .6V1h.9l3.5 3.5L9.8 8z" />
      <path d="M15 7.1v2.1c0 2.2-1.8 4.1-4.1 4.1-.3 0-.5 0-.8-.1l-3-.6V15h-.9l-3.5-3.5L6.2 8h.9v2.8l3.4.7h.5c1.3 0 2.3-1 2.3-2.3V7.1H15z" />
    </>
  ),
  TableGame: () => (
    <path d="m3.4 3-2 .5.8 3.2L3.4 3zM13.9 11l-.9 3.8 1.9-.7-1-3.1zM5 .9 1.4 14.5l9.7 2.6 3.6-13.5L5 .9Zm.9 3c.1-.5.6-.7 1-.6.4.1.7.6.6 1.1-.1.5-.6.7-1 .6-.4-.1-.7-.6-.6-1.1ZM8 10.8v.2l-1-.3v-.2l.6-.8-.5.2h-.5c-.5-.1-.7-.6-.6-1.1 0-.2.2-.4.4-.6L8.5 7l1.2 2.1c.1.2.1.4 0 .7-.1.5-.6.8-1.1.6-.2 0-.3-.1-.4-.3l-.3-.4v1Zm2.1 3.3c-.1.5-.6.7-1 .6s-.7-.6-.6-1.1.6-.7 1-.6c.4.1.7.6.6 1.1Z" />
  ),
  Theatre: () => (
    <path d="M1.3 2h13.3c.2 0 .4.1.6.2.2.2.2.4.2.6v10.3c0 .2-.1.4-.2.6-.2.2-.4.2-.6.2H1.3c-.2 0-.4-.1-.6-.2-.2-.2-.2-.4-.2-.6V2.8c0-.2.1-.4.2-.6s.4-.2.6-.2Zm.9 1.7v8.7h11.7V3.7H2.2Z" />
  ),
  Token: () => (
    <path d="M8 2C4.7 2 2 4.7 2 8s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6zm2.3 6.7-1.6 1.6c-.4.4-1 .4-1.4 0L5.7 8.7c-.4-.4-.4-1 0-1.4l1.6-1.6c.4-.4 1-.4 1.4 0l1.6 1.6c.4.4.4 1 0 1.4z" />
  ),
  Transactions: () => (
    <>
      <polygon points="12 6 12 12 6 12 6 16 16 16 16 6 12 6" />
      <path d="M10,0H0v10h10V0ZM3,4c-.6,0-1-.4-1-1s.4-1,1-1,1,.4,1,1-.4,1-1,1ZM7,8c-.6,0-1-.4-1-1s.4-1,1-1,1,.4,1,1-.4,1-1,1Z" />
    </>
  ),
  Transactions2: () => <path d="M5.3 2.7V0H4L0 4l4 4h1.3V5.3H16V2.7H5.3zM10.7 8v2.7H0v2.6h10.7V16H12l4-4-4-4h-1.3z" />,
  Upload: () => (
    <>
      <path d="M7.3 10.7h1.4V6H12L8 2 4 6h3.3z" />
      <path d="M13.3 8v4.7H2.7V8H1.3v5.3c0 .2.1.3.2.5.2.1.3.2.5.2h12c.2 0 .3-.1.5-.2.1-.1.2-.3.2-.5V8h-1.4z" />
    </>
  ),
  Vault: () => (
    <>
      <path d="M1,1v14h14V1H1ZM11,11.5h-6v-5h1v-1c0-1.1.9-2,2-2s2,.9,2,2v1h1v5Z" />
      <path d="M8,4.5c-.6,0-1,.4-1,1v1h2v-1c0-.6-.4-1-1-1Z" />
    </>
  ),
  Vault2: () => (
    <>
      <path d="M1,1v14h14V1H1ZM11,11.5h-6v-5h1v-1c0-1.1.9-2,2-2s2,.9,2,2v1h1v5Z" />
      <path d="M8,4.5c-.6,0-1,.4-1,1v1h2v-1c0-.6-.4-1-1-1Z" />
    </>
  ),
  Verify: () => (
    <path d="M8 16c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm-.8-4.8 5.7-5.7-1.1-1.1-4.6 4.5-2.3-2.2-1.1 1.1 3.4 3.4z" />
  ),
  Verify2: () => (
    <path d="M15.8 1c-.2-.2-.4-.2-.6-.2H.8C.6.8.4.9.2 1s-.2.4-.2.6v12.8c0 .2.1.4.2.6.2.2.4.2.6.2h14.4c.2 0 .4-.1.6-.2.1-.2.2-.4.2-.6V1.6c0-.2-.1-.4-.2-.6zM8.1 9.9 1.9 4.6l1-1.2L8 7.7l5-4.4 1 1.2-5.9 5.4z" />
  ),
  VIP: () => <path d="m14 4-3 3-2-6H7L5 7 2 4H0l2 11h12l2-11h-2Zm-5.5 9h-1L6 11l1.5-2h1l1.5 2-1.5 2Z" />,
  Wallet: () => (
    <path d="M1 4C1 2.34315 2.34315 1 4 1H15V3H4V5H15V15H4C2.34315 15 1 13.6569 1 12V4ZM12 11C12.5523 11 13 10.5523 13 10C13 9.44771 12.5523 9 12 9C11.4477 9 11 9.44771 11 10C11 10.5523 11.4477 11 12 11Z" />
  ),
  Whitepaper: () => (
    <>
      <path d="M14 2H2v12h6V8h6V2z" />
      <path d="M14 9.7H9.7V14h.9l3.4-3.4v-.9z" />
    </>
  ),
  Withdraw: () => (
    <path d="M12.8.8H.8C.6.8.4.9.2 1s-.2.4-.2.6V4h12.8V.8zM15.8 5.8c-.2-.2-.4-.2-.6-.2H0v8.8c0 .2.1.4.2.6.2.2.4.2.6.2h14.4c.2 0 .4-.1.6-.2.1-.2.2-.4.2-.6v-8c0-.2-.1-.4-.2-.6zm-3 5.4h-2.4V9.6h2.4v1.6z" />
  ),
  Bitcoin: () => (
    <>
      <path
        d="M2.5 0h15C18.9 0 20 1.1 20 2.5v15c0 1.4-1.1 2.5-2.5 2.5h-15C1.1 20 0 18.9 0 17.5v-15C0 1.1 1.1 0 2.5 0z"
        fill="#f7931a"
      />
      <path
        d="M14.5 8.8c.2-1.3-.8-1.9-2.1-2.4l.4-1.7-1-.3-.4 1.7c-.3-.1-.6-.1-.8-.2l.4-1.6L9.9 4l-.4 1.7c-.2 0-.4-.1-.6-.1l-1.5-.4-.2 1.1s.8.2.7.2c.4.1.5.4.5.6L7.9 9H8h-.1l-.7 2.7c-.1.1-.2.3-.5.2l-.8-.2-.4 1.3 1.3.3c.2.1.5.1.7.2l-.4 1.7 1 .3.4-1.7c.4.1.7.2.9.2L9 15.7l1 .3.4-1.7c1.8.3 3.1.2 3.6-1.4.4-1.3 0-2-.9-2.5.7-.2 1.2-.7 1.4-1.6zm-2.4 3.4c-.3 1.3-2.5.6-3.2.4l.6-2.3c.7.2 3 .5 2.6 1.9zm.3-3.4c-.3 1.2-2.1.6-2.7.4l.5-2.1c.7.2 2.5.5 2.2 1.7z"
        fill="#fff"
      />
    </>
  ),
  Twitter: () => (
    <path d="m9.5 6.8 6-6.8h-1.4L8.9 5.8 4.8 0H0l6.2 8.8L0 15.9h1.4l5.5-6.1 4.4 6.1h4.8L9.6 6.8ZM7.6 9 7 8.1l-5.1-7h2.2l4.1 5.6.6.9 5.3 7.3h-2.2l-4.3-6Z" />
  ),
  Telegram: () => (
    <path d="M14.6 1.7s1.5-.6 1.4.8c0 .6-.4 2.5-.7 4.6l-1 6.2s-.1.9-.8 1.1c-.7.2-1.9-.6-2.1-.7-.2-.1-3.1-1.9-4.1-2.8-.3-.2-.6-.7 0-1.3l4.3-4c.5-.5 1-1.6-1.1-.2L4.9 9.2s-.7.4-1.9 0L.3 8.4s-1-.6.7-1.2c4.1-1.8 9.2-3.7 13.6-5.5z" />
  ),
  Website: () => (
    <>
      <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 1 0 1.5-.1L9 14.3s-.2.1-.3.2v-1L7.5 9.8v-.6 1.4c-.6 0-1.3 0-1.8.2-.1-.6-.2-1.3-.2-2h2.1v.5c0-.5.2-.9.5-1.3.3-.3.8-.5 1.3-.5h.6l3.7 1.2h1.2v.4l1.3.4c0-.5.1-1 .1-1.5C16 3.6 12.4 0 8 0Zm-.7 11.8v2.6c-.6-.4-1.1-1.2-1.5-2.4.5 0 1-.1 1.5-.1ZM2.4 4.4c.5.3 1.1.5 1.8.6-.1.7-.2 1.5-.3 2.3H1.4c.1-1.1.5-2.1 1-2.9Zm-1 4.3H4c0 .8.1 1.6.3 2.3-.7.2-1.3.4-1.8.6-.6-.9-.9-1.9-1-3Zm3.9 5.4c-.8-.3-1.4-.8-2-1.4.4-.2.8-.3 1.2-.4.2.7.5 1.3.8 1.8ZM4.5 3.7c-.4-.1-.9-.3-1.2-.4.6-.6 1.3-1.1 2-1.4-.3.5-.6 1.1-.8 1.8Zm2.8 3.6H5.2c0-.7.1-1.4.2-2 .6 0 1.2.2 1.8.2v1.8Zm0-3.1c-.5 0-1 0-1.5-.1.4-1.2.9-2.1 1.5-2.4v2.6Zm1.4-2.6c.6.4 1.1 1.2 1.5 2.4-.5 0-1 .1-1.5.1V1.6Zm0 5.7V5.5c.6 0 1.3 0 1.8-.2.1.6.2 1.3.2 2H8.6Zm2-5.4c.8.3 1.5.8 2 1.4-.4.2-.8.3-1.2.4-.2-.7-.5-1.3-.8-1.8Zm1.4 5.4c0-.8-.1-1.6-.3-2.3.7-.2 1.3-.4 1.8-.6.6.9.9 1.9 1 2.9H12Z" />
      <path d="M11.3 16c-.2 0-.4-.1-.5-.3L8.7 9.4c0-.3 0-.5.3-.6h.3l6.3 2.1c.3 0 .4.4.3.6 0 .1-.2.3-.3.3l-2.8 1.1-1.1 2.8c0 .2-.3.3-.5.3Z" />
    </>
  ),
  Invalid: () => (
    <>
      <path d="M0 8c0-4.4 3.6-8 8-8s8 3.6 8 8-3.6 8-8 8-8-3.6-8-8Z" style={{ fillOpacity: 0.33 }} />
      <path d="M11.1 6 10 4.9l-2 2-2-2L4.9 6l2 2-2 2L6 11.1l2-2 2 2 1.1-1.1-2-2 2-2Z" />
    </>
  ),
} as const;

export type IconNames = keyof typeof Icons;
export default Icons;
