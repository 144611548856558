import { Nav, NavItem } from "reactstrap";
import { Link } from "react-router-dom";
import { NavLink as NavLinkStrap } from "reactstrap";
import { useLocation } from "react-router-dom";

interface Props {
  className?: string;
}

function MobileMenu({ className }: Props) {
  const { pathname } = useLocation();

  return (
    <div className={`${className ? className : `c-header__navigation`}`}>
      <Nav className="navigation-list">
        <NavItem className="navigation-item">
          <NavLinkStrap tag={Link} to="/" className={pathname === "/" ? "c-header__link is-active" : "c-header__link"}>
            Pump Alerts
          </NavLinkStrap>
        </NavItem>
        <NavItem className="navigation-item">
          <NavLinkStrap
            tag={Link}
            to="/raydium-alerts"
            className={pathname === "/raydium-alerts" ? "c-header__link is-active" : "c-header__link"}
          >
            Raydium Alerts
          </NavLinkStrap>
        </NavItem>
        <NavItem className="navigation-item">
          <NavLinkStrap
            tag={Link}
            to="/moonshot-alerts"
            className={pathname === "/moonshot-alerts" ? "c-header__link is-active" : "c-header__link"}
          >
            Moonshot Alerts
          </NavLinkStrap>
        </NavItem>
        <NavItem className="navigation-item">
          <NavLinkStrap tag={Link} to="https://t.me/PlonkBot_bot" className="c-header__link" target="_blank">
            Start Trading
          </NavLinkStrap>
        </NavItem>
        {/* <NavItem className="navigation-item">
          <NavLinkStrap
            tag={Link}
            to="/memescope"
            className={pathname === "/memescope" ? "c-header__link is-active" : "c-header__link"}
          >
            Memescope
          </NavLinkStrap>
        </NavItem>
        <NavItem className="navigation-item">
          <NavLinkStrap
            tag={Link}
            to="/holdings"
            className={pathname === "/holdings" ? "c-header__link is-active" : "c-header__link"}
          >
            Holdings
          </NavLinkStrap>
        </NavItem>
        <NavItem className="navigation-item">
          <NavLinkStrap
            tag={Link}
            to="/leaderboard"
            className={pathname === "/leaderboard" ? "c-header__link is-active" : "c-header__link"}
          >
            Leaderboard
          </NavLinkStrap>
        </NavItem> */}
      </Nav>
    </div>
  );
}

export default MobileMenu;
