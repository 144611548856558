import { Fragment } from "react";
import { Clock } from "react-feather";

import TokenPlaceholder from "../../../assets/images/token-placeholder.png";
import ClickToCopy from "../../../components/common/ClickToCopy";
import Icon from "../../../components/common/Icon";
import { formatNumber } from "../../../utils/general";
import TimerRowSection from "./TimerRowSection";
import AppConfig from "../../../config/config";
import { INewPairToken } from "../../../redux/types";

interface Props {
  tokens: Array<INewPairToken>;
  view?: "DexPaid" | "NewPairs" | "KOTH" | "RaydiumNewPairs";
}

function GridDataTable({ tokens, view = "NewPairs" }: Props) {
  if (tokens.length === 0) {
    return <p className="w-100 text-center mt-4">No data available</p>;
  }

  return (
    <div className="c-dashboard">
      <div className="c-dashboard__grid">
        <Fragment>
          {tokens.map((token, key) => (
            <div className="c-grid__card" key={key}>
              <figure className="c-grid__card--image">
                <img
                  src={token.tokenImageUri ? token.tokenImageUri : TokenPlaceholder}
                  alt={token.tokenName}
                  className="img-fluid"
                />
              </figure>
              <div className="c-grid__card--detail">
                <div className="c-grid__card--detail-top">
                  <div className="c-table__cell mb-3">
                    <div className="c-table__cell__wrap w-100 u-flexed u-justify-btw">
                      <div className="c-table__cell flex-shrink-0 w-180px">
                        <div className="fs-13 w-100 mb-0">
                          <span className="u-text-white">{token.tokenSymbol}</span>
                          <span className="mx-1">/</span>
                          <span className="u-text-offwhite">SOL</span>
                        </div>

                        <div className="w-100 fs-13 u-flexed u-align-center mb-0.5">
                          <ClickToCopy address={token.mintAccount} />
                        </div>
                      </div>

                      <div className="ms-auto flex-shrink-0">
                        <div className="c-table__cell__wrap fs-13">
                          <span className="size-14px p-0 me-1 u-icon">
                            <Clock className="" color="#b5b7da" />
                          </span>
                          <span className="fs-12 u-text-white">
                            <TimerRowSection createdValue={token.createdAt} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="c-grid__card--detail-body pb-3">
                  <div className="c-table__cell">
                    <span className="fs-13 u-text-white text-truncate name text-break">{token.tokenName}</span>
                    <div className="w-100 pt-2 pb-3">
                      <p className="fs-13 line-clamp line-clamp-2 text-tertiary m-0 text-break description">
                        {token.tokenDescription ? token.tokenDescription : null}
                      </p>
                    </div>

                    {view !== "RaydiumNewPairs" && (
                      <div className="c-table__cell w-100">
                        <div className="c-table__cell__wrap md:flex-col md:align-start">
                          <div className="fs-13 fw-bold u-text-offwhite me-1">Dev Tokens:</div>
                          <div className="c-table__cell u-align-center">
                            <div className="c-table__cell__wrap">
                              <span className="u-text-white fs-13">
                                {parseInt(token.devTokensBought) > 0
                                  ? formatNumber(parseInt(token.devTokensBought))
                                  : "-"}
                              </span>
                              {parseInt(token.devTokensBought) > 0 && (
                                <>
                                  <span
                                    className={`c-table__cell__subtext${
                                      token.devTokensPercent >= 7 ? ` u-text-danger` : ` u-text-success`
                                    } ms-1`}
                                  >
                                    ({token.devTokensPercent}%)
                                  </span>
                                  <span className="size-14px ms-2">
                                    {token.devTokensPercent < 7 && <Icon name="Verify" size="14" fill="#22d17a" />}
                                    {token.devTokensPercent >= 7 && <Icon name="Invalid" size="14" fill="#fe4343" />}
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {view === "RaydiumNewPairs" && (
                      <>
                        <div className="c-table__cell w-100">
                          <div className="c-table__cell__wrap md:flex-col md:align-start">
                            <div className="fs-13 fw-bold u-text-offwhite me-1">Top 20 Holding:</div>
                            <div className="c-table__cell u-align-center">
                              <div className="c-table__cell__wrap">
                                <span className="u-text-white fs-13">
                                  {token.raydiumPairInfo.top20 > 0 ? `${token.raydiumPairInfo.top20.toFixed(2)}%` : "-"}
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* <div className="c-table__cell__wrap md:flex-col md:align-start mt-2">
                            <div className="fs-13 fw-bold u-text-offwhite me-1">Top 5 Holders:</div>
                          </div>
                          {Object.keys(token.raydiumPairInfo.holderData).map((holderKey: string) => {
                            return (
                              <div className="c-table__cell__wrap md:flex-col md:align-start mt-1">
                                <span className="fs-13 text-tertiary">
                                  {holderKey.substring(0, 5)}...
                                  {holderKey.substring(holderKey.length - 5)}:{" "}
                                  <span className="u-text-white fs-13">
                                    {token.raydiumPairInfo.holderData[holderKey].toFixed(2)}%
                                  </span>
                                </span>
                              </div>
                            );
                          })} */}
                        </div>
                      </>
                    )}

                    {view === "DexPaid" && (
                      <div className="c-table__cell w-100">
                        <div className="c-table__cell__wrap md:flex-col md:align-start">
                          <div className="fs-13 fw-bold u-text-offwhite me-1">Market Cap:</div>
                          <div className="c-table__cell u-align-center">
                            <div className="c-table__cell__wrap">
                              <span className="u-text-white fs-13">
                                {parseInt(token.dexPaidInfo.marketCap) > 0
                                  ? formatNumber(parseInt(token.dexPaidInfo.marketCap))
                                  : "-"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {view === "KOTH" && (
                      <>
                        <div className="c-table__cell w-100">
                          <div className="c-table__cell__wrap md:flex-col md:align-start">
                            <div className="fs-13 fw-bold u-text-offwhite me-1">Market Cap:</div>
                            <div className="c-table__cell u-align-center">
                              <div className="c-table__cell__wrap">
                                <span className="u-text-white fs-13">
                                  {parseInt(token.kothInfo.marketCap) > 0
                                    ? formatNumber(parseInt(token.kothInfo.marketCap))
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="c-table__cell w-100">
                          <div className="c-table__cell__wrap md:flex-col md:align-start">
                            <div className="fs-13 fw-bold u-text-offwhite me-1">Reply Count:</div>
                            <div className="c-table__cell u-align-center">
                              <div className="c-table__cell__wrap">
                                <span className="u-text-white fs-13">{token.kothInfo.replyCount}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="c-grid__card--detail-bottom">
                  <div className="c-table__cell">
                    <div className="c-table__cell__wrap w-100 u-flexed u-justify-btw">
                      <ul className="c-header__social ms-0 mt-0.5">
                        {token.twitterUrl ? (
                          <li className="c-header__social__item">
                            <a
                              href={token.twitterUrl}
                              className="size-16px"
                              target="_blank"
                              rel="noreferrer"
                              data-balloon="Twitter"
                              data-balloon-pos="up"
                            >
                              <Icon name="Twitter" size="16" fill="currentColor" />
                            </a>
                          </li>
                        ) : null}
                        {token.telegramUrl ? (
                          <li className="c-header__social__item">
                            <a
                              href={token.telegramUrl}
                              className="size-16px p-0"
                              target="_blank"
                              rel="noreferrer"
                              data-balloon="Telegram"
                              data-balloon-pos="up"
                            >
                              <Icon name="Telegram" size="16" fill="currentColor" />
                            </a>
                          </li>
                        ) : null}
                        {token.websiteUrl ? (
                          <li className="c-header__social__item">
                            <a
                              href={token.websiteUrl}
                              className="size-16px p1x"
                              target="_blank"
                              rel="noreferrer"
                              data-balloon="Website"
                              data-balloon-pos="up"
                            >
                              <Icon name="Website" size="14" fill="currentColor" />
                            </a>
                          </li>
                        ) : null}
                        <li className="c-header__social__item">
                          <a
                            href={`${
                              token.tokenSource === "MOONSHOT" || token.tokenSource === "RAYDIUM"
                                ? AppConfig.MOONSHOT_URL
                                : AppConfig.PUMP_URL
                            }/${token.mintAccount}`}
                            className="size-16px p-0"
                            target="_blank"
                            data-balloon={`${
                              token.tokenSource === "RAYDIUM"
                                ? "Raydium"
                                : token.tokenSource === "MOONSHOT"
                                ? "Moonshot"
                                : "Pump.fun"
                            }`}
                            data-balloon-pos="up"
                            rel="noreferrer"
                          >
                            <div
                              className={`c-header__social__source ${
                                token.tokenSource === "RAYDIUM"
                                  ? "c-header__social__raydium"
                                  : token.tokenSource === "MOONSHOT"
                                  ? "c-header__social__moonshot"
                                  : "c-header__social__pump"
                              }`}
                            ></div>
                          </a>
                        </li>
                      </ul>

                      <a
                        href={`${AppConfig.PLONKBOT_TG_URL}?start=a_${token.mintAccount}`}
                        target="_blank"
                        rel="noreferrer"
                        className="c-btn-buy px-3 ms-auto"
                      >
                        Buy
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Fragment>
      </div>
    </div>
  );
}

export default GridDataTable;
