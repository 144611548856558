import { ViewTypeObj } from "../../../config/Constant";
import { INewPairToken } from "../../../redux/types";
import GridDataTable from "./GridDataTable";
import ListDataTable from "./ListDataTable";

interface Props {
  displayView: string;
  tokensList: Array<INewPairToken>;
}

function RaydiumAlertsComponent({ displayView, tokensList }: Props) {
  return displayView === ViewTypeObj.listView ? (
    <ListDataTable tokens={tokensList} view="RaydiumNewPairs" />
  ) : (
    <GridDataTable tokens={tokensList} view="RaydiumNewPairs" />
  );
}

export default RaydiumAlertsComponent;
