import { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane, Input } from "reactstrap";
import { Grid, Menu } from "react-feather";

import PageTitle from "../../components/common/PageTitle";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { addToken, newPairsSelector } from "../../redux/slices/newPairsSlice";
import { useGetLatestMoonshotTokensQuery } from "../../api/apiSlice";
import AllAlertsComponent from "./partials/AllAlertsComponent";
import DevLessThen7Component from "./partials/DevLessThen7Component";
import SocialAndWebAndDev7Component from "./partials/SocialAndWebAndDev7Component";
import SocialAndWebComponent from "./partials/SocialAndWebComponent";
import { MoonshotListTabObj, ViewTypeObj } from "../../config/Constant";

const MoonshotAlertsPage = () => {
  const dispatch = useAppDispatch();

  const {
    moonshotAllTokens,
    moonshotDevLessThan7PercentAndSocialsTokens,
    moonshotSocialAndWebTokens,
    moonshotDevLessThan7PercentTokens,
  } = useAppSelector(newPairsSelector);

  // Using a query hook automatically fetches data and returns query values
  const { data: latestTokensResponse, isLoading, isError } = useGetLatestMoonshotTokensQuery();

  const [displayView, setDisplayView] = useState<string>(ViewTypeObj.listView);
  const [activeTab, setActiveTab] = useState<string>(MoonshotListTabObj.allAlerts.key);

  useEffect(() => {
    if (!isLoading && !isError && latestTokensResponse?.latestTokens) {
      for (let i = latestTokensResponse.latestTokens.length - 1; i >= 0; i--) {
        dispatch(addToken(latestTokensResponse.latestTokens[i]));
      }
    }
  }, [latestTokensResponse?.latestTokens, isLoading, isError, dispatch]);

  return (
    <div className="c-body__wrap">
      <div className="c-discover">
        <div className="c-discover__wrap">
          <PageTitle
            className="u-heading mb-3"
            title="Moonshot New Pairs"
            description="New token pairs in the last 24-hours updated in real-time."
          />
          <div className="c-filters">
            <div className="c-discover__filter gap-2 w-auto sm:flex-col d-none d-md-flex">
              {Object.values(MoonshotListTabObj).map((obj, key) => (
                <button
                  key={key}
                  type="button"
                  className={`d-flex align-items-center c-btn-filter ${activeTab === obj.key ? "is-opened" : ""}`}
                  onClick={() => setActiveTab(obj.key)}
                >
                  {obj.value}
                </button>
              ))}
            </div>

            <div className="d-block d-md-none form-horizontal">
              <div className="custom-select">
                <Input id="exampleSelect" name="select" type="select" onChange={(e) => setActiveTab(e.target.value)}>
                  {Object.values(MoonshotListTabObj).map((obj, key) => (
                    <option value={obj.key} key={key}>
                      {obj.value}
                    </option>
                  ))}
                </Input>
              </div>
            </div>

            <div className="c-btn-group flex-shrink-0 ms-auto">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={`nav-link ${displayView === ViewTypeObj.listView ? "active" : ""}`}
                    onClick={() => {
                      setDisplayView(ViewTypeObj.listView);
                    }}
                  >
                    <Menu className="" role="button" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`nav-link ${displayView === ViewTypeObj.gridView ? "active" : ""}`}
                    onClick={() => {
                      setDisplayView(ViewTypeObj.gridView);
                    }}
                  >
                    <Grid className="" role="button" />
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={MoonshotListTabObj.allAlerts.key}>
              <AllAlertsComponent tokensList={moonshotAllTokens} displayView={displayView} />
            </TabPane>
            <TabPane tabId={MoonshotListTabObj.devLess7.key}>
              <DevLessThen7Component tokensList={moonshotDevLessThan7PercentTokens} displayView={displayView} />
            </TabPane>
            <TabPane tabId={MoonshotListTabObj.socialWeb.key}>
              <SocialAndWebComponent tokensList={moonshotSocialAndWebTokens} displayView={displayView} />
            </TabPane>
            <TabPane tabId={MoonshotListTabObj.devLess7SocialWeb.key}>
              <SocialAndWebAndDev7Component
                tokensList={moonshotDevLessThan7PercentAndSocialsTokens}
                displayView={displayView}
              />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </div>
  );
};

export default MoonshotAlertsPage;
