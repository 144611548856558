import Icon from "../../components/common/Icon";
import { IconNames } from "./Icons";

interface Props {
  color?: string;
  title?: string;
  description?: string;
  iconName?: IconNames;
  className?: string;
  size?: "8" | "10" | "12" | "14" | "16" | "18" | "20" | "24" | "32" | "40";
  backButtonClickHandler?: () => void;
}

const PageTitle = ({
  className,
  color,
  title,
  iconName,
  size,
  description,
  backButtonClickHandler,
}: Props) => {
  return (
    <div className={className}>
      <div className="d-inline-flex align-items-center">
        {backButtonClickHandler ? (
          <button
            type="button"
            className="btn-back"
            onClick={() => backButtonClickHandler()}
          ></button>
        ) : null}
        {iconName ? (
          <span className="size-20px position-relative me-2">
            <Icon name={iconName} size={size} fill={color} />
          </span>
        ) : null}
        <div className="">
          <h3 className="u-heading__title" style={{ color: color }}>
            {title}
          </h3>
          <p className="u-heading__excerpt" style={{ color: color }}>
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PageTitle;
