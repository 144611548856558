import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { io, Socket } from "socket.io-client";
import { toast, ToastContainer } from "react-toastify";

// Import Static UIs
import Header from "./components/common/Header";

// Pages
import PumpAlertsPage from "./pages/new-pairs";
import MoonshotAlertsPage from "./pages/new-pairs/moonshot-alerts";
// import DiscoverPage from "../src/pages/discover";

import { ClientToServerEvents, ServerToClientEvents } from "./types/types";
import AppConfig from "./config/config";
import { IError, IRoomJoinSuccess } from "./types/server-to-client-events";
import { ILatestToken } from "./api/types";
import { useAppDispatch } from "./redux/hooks";
import { addDexPaidToken, addKOTHToken, addRaydiumToken, addToken } from "./redux/slices/newPairsSlice";
import RaydiumAlertsPage from "./pages/new-pairs/raydium-alerts";

export default function App() {
  const dispatch = useAppDispatch();
  const [socket, setSocket] = useState<Socket<ServerToClientEvents, ClientToServerEvents> | null>(null);

  useEffect(() => {
    if (!socket) {
      return;
    }

    const disconnectHandler = (reason: string) => {
      // console.log("Server disconnect parent page", reason);
      if (reason === "io server disconnect") {
        // the disconnection was initiated by the server, you need to reconnect manually
        socket.connect();
      }
      // else if (reason === "io client disconnect") {
      //   socket.connect();
      // }
    };

    const errorHandler = (data: IError) => {
      toast.error(data.message);
    };

    const roomJoinSuccessHandler = (data: IRoomJoinSuccess) => {
      // console.log(data);
    };

    const tokenAlertHandler = (token: ILatestToken) => {
      // console.log(token);
      dispatch(addToken(token));
    };

    const dexPaidAlertHandler = (token: ILatestToken) => {
      dispatch(addDexPaidToken(token));
    };

    const kothAlertHandler = (token: ILatestToken) => {
      dispatch(addKOTHToken(token));
    };

    const newRaydiumPairAlertHandler = (token: ILatestToken) => {
      dispatch(addRaydiumToken(token));
    };

    const connectHandler = () => {
      socket.emit("joinRoom", { roomName: "Alerts" });
    };

    // socket.onAny((event, ...args) => {
    //   console.log(event, args);
    // });

    // disconnect
    socket.on("disconnect", disconnectHandler);
    socket.on("error", errorHandler);
    socket.on("roomJoinSuccess", roomJoinSuccessHandler);
    socket.on("tokenAlert", tokenAlertHandler);
    socket.on("dexPaidAlert", dexPaidAlertHandler);
    socket.on("newKOTHAlert", kothAlertHandler);
    socket.on("newRaydiumPairAlert", newRaydiumPairAlertHandler);
    socket.on("connect", connectHandler);

    return () => {
      socket.off("disconnect", disconnectHandler);
      socket.off("error", errorHandler);
      socket.off("roomJoinSuccess", roomJoinSuccessHandler);
      socket.off("tokenAlert", tokenAlertHandler);
      socket.off("dexPaidAlert", dexPaidAlertHandler);
      socket.off("newKOTHAlert", kothAlertHandler);
      socket.off("newRaydiumPairAlert", newRaydiumPairAlertHandler);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    const socRef = io(AppConfig.apiGateway.SOCKET_URL, {
      autoConnect: false,
      transports: ["websocket", "polling"],
    });

    socRef.connect();

    setSocket(socRef);
  }, []);

  return (
    <Router>
      <section className="page-wrapper home-page">
        <Header />
        <main className="c-body">
          <Routes>
            {/* <Route path="/discover" element={<DiscoverPage />} />
            <Route path="/new-pairs" element={<NewPairPage />} /> */}
            <Route path="/moonshot-alerts" element={<MoonshotAlertsPage />} />
            <Route path="/raydium-alerts" element={<RaydiumAlertsPage />} />
            <Route path="*" element={<PumpAlertsPage />} />
          </Routes>
        </main>

        <ToastContainer autoClose={5000} theme="dark" position="top-left" />
      </section>
    </Router>
  );
}
