import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { UncontrolledTooltip } from "reactstrap";

import Icon from "../../components/common/Icon";

interface Props {
  address: string;
}

function ClickToCopy({ address }: Props) {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setShowTooltip(true);
    }, 10);

    setTimeout(() => {
      setIsCopied(false);
      setShowTooltip(false);
    }, 3000);
  };

  return (
    <div className="input-group-copy z-over">
      <span className="fs-13 fw-semibold u-text-offwhite">
        {address.substring(0, 7)}...
        {address.substring(address.length - 7)}
      </span>
      <CopyToClipboard text={address} onCopy={onCopyText}>
        {isCopied ? (
          <button className="btn-copy btn-copy-success ms-1" id="TokenTooltip">
            <Icon name="Copy" size="16" fill="currentColor" className="position-relative" />
            <UncontrolledTooltip
              placement="top"
              target="TokenTooltip"
              className="modal-tooltip"
              // trigger="hover focus"
              isOpen={showTooltip}
            >
              Address Copied!
            </UncontrolledTooltip>
          </button>
        ) : (
          <button className="btn-copy ms-1">
            <Icon name="Copy" size="16" fill="currentColor" className="position-relative" />
          </button>
        )}
      </CopyToClipboard>
    </div>
  );
}

export default ClickToCopy;
