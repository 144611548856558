import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  // Dropdown,
  // DropdownMenu,
  // DropdownItem,
  // DropdownToggle,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap";

// Import Static Assets
import { Menu } from "react-feather";
import Logo from "../../assets/images/logo.png";
// import ETH from "../../assets/icons/Eth.svg";
// import SOL from "../../assets/icons/Sol.svg";
// import BASE from "../../assets/icons/Base.svg";
// import BLAST from "../../assets/icons/Blast.svg";
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";

function Header() {
  useEffect(() => {
    const header = document.getElementById("appHeader");
    if (header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("is-sticky");
        } else {
          header.classList.remove("is-sticky");
        }
      });
    }
  });

  // const [dropdownFeaturedOpen, setDropdownFeaturedOpen] = useState(false);
  // const toggleFeatured = () => setDropdownFeaturedOpen((prevState) => !prevState);
  // const [selectedFeaturedItem, setSelectedFeaturedItem] = useState("SOL");

  const [show, setShow] = useState(false);
  const toggleOffcanvas = () => {
    setShow(!show);
  };

  return (
    <>
      <header className="c-header" id="appHeader">
        <div className="c-header__wrap">
          <Container fluid className="p-0">
            <Row>
              <Col lg={12}>
                <div className="w-100 d-flex align-items-center justify-content-between">
                  <div className="c-header__left">
                    <a href="/" className="auth-logo flex-shrink-0">
                      <img src={Logo} width={150} height={24} alt="SOL" className="img-fluid" />
                    </a>

                    <div className="ms-4 d-none d-lg-flex">
                      <DesktopMenu className="c-header__navigation" />
                    </div>
                  </div>

                  <div className="c-header__right">
                    {/* <Dropdown
                      className="common-dropdown d-none d-xl-flex"
                      isOpen={dropdownFeaturedOpen}
                      toggle={toggleFeatured}
                    >
                      <DropdownToggle className="btn-block" caret>
                        <span className="size-24px p-0 me-1b">
                          <img src={SOL} width={24} height={24} alt="SOL" className="img-fluid" />
                        </span>
                        <span className="text-tertiary w-50px">{selectedFeaturedItem}</span>
                      </DropdownToggle>
                      <DropdownMenu className="scrollY" end>
                        <div className="dropdown-menu-wrap">
                          <DropdownItem
                            onClick={() => {
                              setSelectedFeaturedItem("ETH");
                            }}
                            className={` ${selectedFeaturedItem === "ETH" ? "is-selected" : ""}`}
                          >
                            <span className="size-24px p-0 me-1b">
                              <img src={ETH} width={24} height={24} alt="ETH" className="img-fluid" />
                            </span>
                            ETH
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setSelectedFeaturedItem("SOL");
                            }}
                            className={` ${selectedFeaturedItem === "SOL" ? "is-selected" : ""}`}
                          >
                            <span className="size-24px p-0 me-1b">
                              <img src={SOL} width={24} height={24} alt="SOL" className="img-fluid" />
                            </span>
                            SOL
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setSelectedFeaturedItem("BLAST");
                            }}
                            className={` ${selectedFeaturedItem === "BLAST" ? "is-selected" : ""}`}
                          >
                            <span className="size-24px p-0 me-1b">
                              <img src={BLAST} width={24} height={24} alt="BLAST" className="img-fluid" />
                            </span>
                            BLAST
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setSelectedFeaturedItem("BASE");
                            }}
                            className={` ${selectedFeaturedItem === "BASE" ? "is-selected" : ""}`}
                          >
                            <span className="size-24px p-0 me-1b">
                              <img src={BASE} width={24} height={24} alt="BASE" className="img-fluid" />
                            </span>
                            BASE
                          </DropdownItem>
                        </div>
                      </DropdownMenu>
                    </Dropdown> */}

                    <ul className="c-header__social d-xl-flex">
                      <li className="c-header__social__item">
                        <a href="https://x.com/PlonkBot" className="" target="_blank" rel="noreferrer">
                          <div className="c-header__social__item__icon c-mask__icon c-mask__icon__twitter"></div>
                        </a>
                      </li>
                      <li className="c-header__social__item">
                        <a href="https://t.me/PlonkBotJoin" className="" target="_blank" rel="noreferrer">
                          <div className="c-header__social__item__icon c-mask__icon c-mask__icon__telegram"></div>
                        </a>
                      </li>
                    </ul>

                    {/* <button type="button" className="c-header__wallet">
                      <div className="c-mask__icon c-header__wallet__icon"></div>
                    </button> */}

                    {/* Mobile Menu Toggler */}
                    <button type="button" onClick={() => setShow(!show)} className="ms-2 d-block d-lg-none">
                      <Menu className="" color="#B5B7DA" role="button" />
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </header>

      {/* Mobile Menu */}
      <Offcanvas
        isOpen={show}
        toggle={toggleOffcanvas}
        fade={false}
        direction="end"
        scrollable
        className="u-mobile__menu"
      >
        <OffcanvasHeader
          className="offcanvas-header offcanvas-header-sm p-0"
          toggle={toggleOffcanvas}
        ></OffcanvasHeader>
        <OffcanvasBody className="">
          <div className="w-100 m-0">
            <MobileMenu className="navigation" toggleOffcanvas={toggleOffcanvas} />
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
}

export default Header;
