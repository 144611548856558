import { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane, Input } from "reactstrap";
import { Grid, Menu } from "react-feather";

import PageTitle from "../../components/common/PageTitle";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { addRaydiumToken, newPairsSelector } from "../../redux/slices/newPairsSlice";
import { useGetLatestRaydiumTokensQuery } from "../../api/apiSlice";
import { ListTabObj, RaydiumListTabObj, ViewTypeObj } from "../../config/Constant";
import RaydiumAlertsComponent from "./partials/RaydiumAlertsComponent";

const RaydiumAlertsPage = () => {
  const dispatch = useAppDispatch();
  const { raydiumTokens } = useAppSelector(newPairsSelector);

  // Using a query hook automatically fetches data and returns query values
  const {
    data: tokensResponse,
    isLoading: isLoadingTokens,
    isError: isErrorLoadingTokens,
  } = useGetLatestRaydiumTokensQuery();

  const [displayView, setDisplayView] = useState<string>(ViewTypeObj.listView);
  const [activeTab, setActiveTab] = useState<string>(ListTabObj.allAlerts.key);

  useEffect(() => {
    if (!isLoadingTokens && !isErrorLoadingTokens && tokensResponse) {
      [...tokensResponse.latestTokens].reverse().forEach((token) => {
        dispatch(addRaydiumToken(token));
      });
    }
  }, [tokensResponse, isLoadingTokens, isErrorLoadingTokens, dispatch]);

  return (
    <div className="c-body__wrap">
      <div className="c-discover">
        <div className="c-discover__wrap">
          <PageTitle
            className="u-heading mb-3"
            title="Raydium New Pairs"
            description="New token pairs in the last 24-hours updated in real-time."
          />
          <div className="c-filters">
            <div className="c-discover__filter gap-2 w-auto sm:flex-col d-none d-md-flex">
              {Object.values(RaydiumListTabObj).map((obj, key) => (
                <button
                  key={key}
                  type="button"
                  className={`d-flex align-items-center c-btn-filter ${activeTab === obj.key ? "is-opened" : ""}`}
                  onClick={() => setActiveTab(obj.key)}
                >
                  {obj.value}
                </button>
              ))}
            </div>

            <div className="d-block d-md-none form-horizontal">
              <div className="custom-select">
                <Input id="exampleSelect" name="select" type="select" onChange={(e) => setActiveTab(e.target.value)}>
                  {Object.values(RaydiumListTabObj).map((obj, key) => (
                    <option value={obj.key} key={key}>
                      {obj.value}
                    </option>
                  ))}
                </Input>
              </div>
            </div>

            <div className="c-btn-group flex-shrink-0 ms-auto">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={`nav-link ${displayView === ViewTypeObj.listView ? "active" : ""}`}
                    onClick={() => {
                      setDisplayView(ViewTypeObj.listView);
                    }}
                  >
                    <Menu className="" role="button" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`nav-link ${displayView === ViewTypeObj.gridView ? "active" : ""}`}
                    onClick={() => {
                      setDisplayView(ViewTypeObj.gridView);
                    }}
                  >
                    <Grid className="" role="button" />
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={ListTabObj.allAlerts.key}>
              <RaydiumAlertsComponent tokensList={raydiumTokens} displayView={displayView} />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </div>
  );
};

export default RaydiumAlertsPage;
