import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { AppState } from "../store";
import AppConfig from "../../config/config";
import { INewPairToken } from "../types";
import { ILatestToken } from "../../api/types";

export interface NewPairsState {
  // PUMP
  pumpAllTokens: Array<INewPairToken>;
  pumpDevLessThan7PercentTokens: Array<INewPairToken>;
  pumpSocialAndWebTokens: Array<INewPairToken>;
  pumpDevLessThan7PercentAndSocialsTokens: Array<INewPairToken>;
  pumpAllTokensRowClass: "odd" | "even";
  pumpDevLessThan7PercentTokensRowClass: "odd" | "even";
  pumpSocialAndWebTokensRowClass: "odd" | "even";
  pumpDevLessThan7PercentAndSocialsTokensRowClass: "odd" | "even";

  // MOONSHOT
  moonshotAllTokens: Array<INewPairToken>;
  moonshotDevLessThan7PercentTokens: Array<INewPairToken>;
  moonshotSocialAndWebTokens: Array<INewPairToken>;
  moonshotDevLessThan7PercentAndSocialsTokens: Array<INewPairToken>;
  moonshotAllTokensRowClass: "odd" | "even";
  moonshotDevLessThan7PercentTokensRowClass: "odd" | "even";
  moonshotSocialAndWebTokensRowClass: "odd" | "even";
  moonshotDevLessThan7PercentAndSocialsTokensRowClass: "odd" | "even";

  // DEXPAID
  dexPaidTokens: Array<INewPairToken>;
  dexPaidTokensRowClass: "odd" | "even";

  // KOTH
  kothTokens: Array<INewPairToken>;
  kothTokensRowClass: "odd" | "even";

  // Raydium Token
  raydiumTokens: Array<INewPairToken>;
  raydiumTokensRowClass: "odd" | "even";
}

const initialState: NewPairsState = {
  // PUMP
  pumpAllTokens: [],
  pumpDevLessThan7PercentTokens: [],
  pumpSocialAndWebTokens: [],
  pumpDevLessThan7PercentAndSocialsTokens: [],
  pumpAllTokensRowClass: "odd",
  pumpDevLessThan7PercentTokensRowClass: "odd",
  pumpSocialAndWebTokensRowClass: "odd",
  pumpDevLessThan7PercentAndSocialsTokensRowClass: "odd",

  // MOONSHOT
  moonshotAllTokens: [],
  moonshotDevLessThan7PercentTokens: [],
  moonshotSocialAndWebTokens: [],
  moonshotDevLessThan7PercentAndSocialsTokens: [],
  moonshotAllTokensRowClass: "odd",
  moonshotDevLessThan7PercentTokensRowClass: "odd",
  moonshotSocialAndWebTokensRowClass: "odd",
  moonshotDevLessThan7PercentAndSocialsTokensRowClass: "odd",

  // DEXPAID
  dexPaidTokens: [],
  dexPaidTokensRowClass: "odd",

  // KOTH
  kothTokens: [],
  kothTokensRowClass: "odd",

  // Raydium Tokens
  raydiumTokens: [],
  raydiumTokensRowClass: "odd",
};

export const newPairsSlice = createSlice({
  name: "newpairs",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    addToken: (state, action: PayloadAction<ILatestToken>) => {
      const token = action.payload;

      const addOrUpdateToken = (
        tokensArray: Array<INewPairToken>,
        rowClassKey:
          | "pumpAllTokensRowClass"
          | "pumpDevLessThan7PercentTokensRowClass"
          | "pumpSocialAndWebTokensRowClass"
          | "pumpDevLessThan7PercentAndSocialsTokensRowClass"
          | "moonshotAllTokensRowClass"
          | "moonshotDevLessThan7PercentTokensRowClass"
          | "moonshotSocialAndWebTokensRowClass"
          | "moonshotDevLessThan7PercentAndSocialsTokensRowClass",
        token: ILatestToken
      ) => {
        // all tokens
        let tokenIndex = -1;

        for (let i = 0; i < tokensArray.length; i++) {
          if (tokensArray[i].mintAccount === token.mintAccount) {
            tokensArray[i] = { ...token, rowClass: tokensArray[i].rowClass };
            tokenIndex = i;
            break;
          }
        }

        if (tokenIndex === -1) {
          state[rowClassKey] = state[rowClassKey] === "even" ? "odd" : "even";
          tokensArray.unshift({ ...token, rowClass: state[rowClassKey] });
        }

        return tokensArray.slice(0, AppConfig.MAX_ARRAY_SIZE); // Keeping MAX 100 entries
      };

      const stateObjectSourceKey = token.tokenSource === "MOONSHOT" ? "moonshot" : "pump";

      state[`${stateObjectSourceKey}AllTokens`] = addOrUpdateToken(
        state[`${stateObjectSourceKey}AllTokens`],
        `${stateObjectSourceKey}AllTokensRowClass`,
        token
      );

      // dev < 7%
      if (token.devTokensPercent < 7) {
        state[`${stateObjectSourceKey}DevLessThan7PercentTokens`] = addOrUpdateToken(
          state[`${stateObjectSourceKey}DevLessThan7PercentTokens`],
          `${stateObjectSourceKey}DevLessThan7PercentTokensRowClass`,
          token
        );
      }

      // Socials
      if (token.telegramUrl || token.twitterUrl || token.websiteUrl) {
        state[`${stateObjectSourceKey}SocialAndWebTokens`] = addOrUpdateToken(
          state[`${stateObjectSourceKey}SocialAndWebTokens`],
          `${stateObjectSourceKey}SocialAndWebTokensRowClass`,
          token
        );

        // Socials and dev < 7%
        if (token.devTokensPercent < 7) {
          state[`${stateObjectSourceKey}DevLessThan7PercentAndSocialsTokens`] = addOrUpdateToken(
            state[`${stateObjectSourceKey}DevLessThan7PercentAndSocialsTokens`],
            `${stateObjectSourceKey}DevLessThan7PercentAndSocialsTokensRowClass`,
            token
          );
        }
      }
    },
    addDexPaidToken: (state, action: PayloadAction<ILatestToken>) => {
      const token = { ...action.payload };

      if (token.dexPaidInfo) {
        token.devTokensPercent = token.dexPaidInfo.devTokensPercent;
        token.devTokensBought = token.dexPaidInfo.devHoldings;
        token.createdAt = token.dexPaidInfo.dexPaidDate;
      }

      state.dexPaidTokensRowClass = state.dexPaidTokensRowClass === "even" ? "odd" : "even";
      state.dexPaidTokens.unshift({ ...token, rowClass: state.dexPaidTokensRowClass });

      state.dexPaidTokens = state.dexPaidTokens.slice(0, AppConfig.MAX_ARRAY_SIZE);
    },
    addKOTHToken: (state, action: PayloadAction<ILatestToken>) => {
      const token = { ...action.payload };

      token.createdAt = token.kothInfo.kothDate;

      state.kothTokensRowClass = state.kothTokensRowClass === "even" ? "odd" : "even";
      state.kothTokens.unshift({ ...token, rowClass: state.kothTokensRowClass });

      state.kothTokens = state.kothTokens.slice(0, AppConfig.MAX_ARRAY_SIZE);
    },
    addRaydiumToken: (state, action: PayloadAction<ILatestToken>) => {
      const token = { ...action.payload };

      state.raydiumTokensRowClass = state.raydiumTokensRowClass === "even" ? "odd" : "even";
      state.raydiumTokens.unshift({ ...token, rowClass: state.raydiumTokensRowClass });

      state.raydiumTokens = state.raydiumTokens.slice(0, AppConfig.MAX_ARRAY_SIZE);
    },
  },
});

export const { addToken, addDexPaidToken, addKOTHToken, addRaydiumToken } = newPairsSlice.actions;

export const newPairsSelector = (state: AppState) => state.newpairs;

export default newPairsSlice.reducer;
