import { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane, Input } from "reactstrap";
import { Grid, Menu } from "react-feather";

import PageTitle from "../../components/common/PageTitle";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { addDexPaidToken, addKOTHToken, addToken, newPairsSelector } from "../../redux/slices/newPairsSlice";
import { useGetPUMPTokensQuery } from "../../api/apiSlice";
import AllAlertsComponent from "./partials/AllAlertsComponent";
import DevLessThen7Component from "./partials/DevLessThen7Component";
import SocialAndWebAndDev7Component from "./partials/SocialAndWebAndDev7Component";
import SocialAndWebComponent from "./partials/SocialAndWebComponent";
import { ListTabObj, ViewTypeObj } from "../../config/Constant";
import DexPaidComponent from "./partials/DexPaidComponent";
import KOTHComponent from "./partials/KOTHComponent";

const PumpAlertsPage = () => {
  const dispatch = useAppDispatch();
  const {
    pumpAllTokens,
    pumpDevLessThan7PercentAndSocialsTokens,
    pumpSocialAndWebTokens,
    pumpDevLessThan7PercentTokens,
    dexPaidTokens,
    kothTokens,
  } = useAppSelector(newPairsSelector);

  // Using a query hook automatically fetches data and returns query values
  const { data: tokensResponse, isLoading: isLoadingTokens, isError: isErrorLoadingTokens } = useGetPUMPTokensQuery();

  const [displayView, setDisplayView] = useState<string>(ViewTypeObj.listView);
  const [activeTab, setActiveTab] = useState<string>(ListTabObj.allAlerts.key);

  useEffect(() => {
    if (!isLoadingTokens && !isErrorLoadingTokens && tokensResponse) {
      [...tokensResponse.latestPUMPTokens].reverse().forEach((token) => {
        dispatch(addToken(token));
      });

      [...tokensResponse.latestDexPaidTokens].reverse().forEach((token) => {
        dispatch(addDexPaidToken(token));
      });

      [...tokensResponse.latestKOTHTokens].reverse().forEach((token) => {
        dispatch(addKOTHToken(token));
      });
    }
  }, [tokensResponse, isLoadingTokens, isErrorLoadingTokens, dispatch]);

  return (
    <div className="c-body__wrap">
      <div className="c-discover">
        <div className="c-discover__wrap">
          <PageTitle
            className="u-heading mb-3"
            title="Pump New Pairs"
            description="New token pairs in the last 24-hours updated in real-time."
          />
          <div className="c-filters">
            <div className="c-discover__filter gap-2 w-auto sm:flex-col d-none d-md-flex">
              {Object.values(ListTabObj).map((obj, key) => (
                <button
                  key={key}
                  type="button"
                  className={`d-flex align-items-center c-btn-filter ${activeTab === obj.key ? "is-opened" : ""}`}
                  onClick={() => setActiveTab(obj.key)}
                >
                  {obj.value}
                </button>
              ))}
            </div>

            <div className="d-block d-md-none form-horizontal">
              <div className="custom-select">
                <Input id="exampleSelect" name="select" type="select" onChange={(e) => setActiveTab(e.target.value)}>
                  {Object.values(ListTabObj).map((obj, key) => (
                    <option value={obj.key} key={key}>
                      {obj.value}
                    </option>
                  ))}
                </Input>
              </div>
            </div>

            <div className="c-btn-group flex-shrink-0 ms-auto">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={`nav-link ${displayView === ViewTypeObj.listView ? "active" : ""}`}
                    onClick={() => {
                      setDisplayView(ViewTypeObj.listView);
                    }}
                  >
                    <Menu className="" role="button" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`nav-link ${displayView === ViewTypeObj.gridView ? "active" : ""}`}
                    onClick={() => {
                      setDisplayView(ViewTypeObj.gridView);
                    }}
                  >
                    <Grid className="" role="button" />
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={ListTabObj.allAlerts.key}>
              <AllAlertsComponent tokensList={pumpAllTokens} displayView={displayView} />
            </TabPane>
            <TabPane tabId={ListTabObj.devLess7.key}>
              <DevLessThen7Component tokensList={pumpDevLessThan7PercentTokens} displayView={displayView} />
            </TabPane>
            <TabPane tabId={ListTabObj.socialWeb.key}>
              <SocialAndWebComponent tokensList={pumpSocialAndWebTokens} displayView={displayView} />
            </TabPane>
            <TabPane tabId={ListTabObj.devLess7SocialWeb.key}>
              <SocialAndWebAndDev7Component
                tokensList={pumpDevLessThan7PercentAndSocialsTokens}
                displayView={displayView}
              />
            </TabPane>
            <TabPane tabId={ListTabObj.dexPaid.key}>
              <DexPaidComponent tokensList={dexPaidTokens} displayView={displayView} />
            </TabPane>
            <TabPane tabId={ListTabObj.koth.key}>
              <KOTHComponent tokensList={kothTokens} displayView={displayView} />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </div>
  );
};

export default PumpAlertsPage;
