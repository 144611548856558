import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import AppConfig from "../config/config";

// Slices
import { newPairsSlice } from "./slices/newPairsSlice";

// API Service
import { apiSlice } from "../api/apiSlice";

export const store = configureStore({
  reducer: {
    [newPairsSlice.name]: newPairsSlice.reducer,
    // Add the generated reducer as a specific top-level slice. MUST BE THE LAST
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: AppConfig.IS_DEV_ENV,
});

// Infer the `AppState` and `AppDispatch` types from the store itself
export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
