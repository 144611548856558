const developmentConfig = {
  apiGateway: {
    BASE_URL: "http://localhost:3001",
    SOCKET_URL: "http://localhost:3001",
    // BASE_URL: "https://webapp-apiuat5432.plonkbot.com",
    // SOCKET_URL: "https://webapp-apiuat5432.plonkbot.com",
  },
};

const productionConfig = {
  apiGateway: {
    BASE_URL: "https://webapp-api.plonkbot.com",
    SOCKET_URL: "https://webapp-api.plonkbot.com",
  },
};

const uatConfig = {
  apiGateway: {
    BASE_URL: "https://webapp-apiuat5432.plonkbot.com",
    SOCKET_URL: "https://webapp-apiuat5432.plonkbot.com",
  },
};

const prod = "production";
const uat = "uat";
const dev = "dev";

const baseConfig =
  process.env.REACT_APP_APP_STAGE === prod
    ? productionConfig
    : process.env.REACT_APP_APP_STAGE === uat
    ? uatConfig
    : developmentConfig;

const AppConfig = {
  APP_STAGE: process.env.REACT_APP_APP_STAGE || prod,
  IS_DEV_ENV: process.env.REACT_APP_APP_STAGE === dev ? true : false,
  IS_UAT_ENV: process.env.REACT_APP_APP_STAGE === uat ? true : false,
  IS_PROD_ENV: process.env.REACT_APP_APP_STAGE === prod ? true : false,
  PUMP_URL: "https://pump.fun",
  MOONSHOT_URL: "https://dexscreener.com/solana",
  PLONKBOT_TG_URL: "https://t.me/PlonkBot_bot",
  APP_VERSION: 1,
  MAX_ARRAY_SIZE: 100,
  ...baseConfig,
};

export default AppConfig;
