import moment from "moment";
import { Fragment, useEffect, useState } from "react";

interface Props {
  createdValue: string;
}

const TimerRowSection = ({ createdValue }: Props) => {
  const [timeDiff, setTimeDiff] = useState<string>("0s");

  useEffect(() => {
    const timerRef = setTimeout(() => {
      let returnData = "0s";
      const betPlaceTIme = moment(createdValue);
      const currentTime = moment.utc();
      const diffDuration = moment.duration(currentTime.diff(betPlaceTIme));
      const diffSec = diffDuration.asSeconds();

      if (diffSec >= 0 && diffSec < 60) {
        returnData = `${Math.round(diffSec)}s`;
      }

      if (diffSec >= 60 && diffSec < 3600) {
        returnData = `${Math.round(diffDuration.asMinutes())}m`;
      }

      if (diffSec >= 3600 && diffSec < 86400) {
        returnData = `${Math.round(diffDuration.asHours())}h`;
      }

      if (diffSec >= 86400) {
        returnData = `${Math.round(diffDuration.asDays())}d`;
      }

      setTimeDiff(returnData);
    }, 1000);

    return () => {
      clearTimeout(timerRef);
    };
  }, [createdValue, timeDiff]);

  return <Fragment>{timeDiff}</Fragment>;
};

export default TimerRowSection;
