import { Clock } from "react-feather";

import LogoP from "../../../assets/images/logo-placeholder.png";
import Icon from "../../../components/common/Icon";
import { formatNumber } from "../../../utils/general";
import TimerRowSection from "./TimerRowSection";
// import ClickToCopyListView from "./ClickToCopyListView";
import AppConfig from "../../../config/config";
import { Fragment } from "react";
import ClickToCopy from "../../../components/common/ClickToCopy";
import { INewPairToken } from "../../../redux/types";

function ListDataTable(props: {
  tokens: Array<INewPairToken>;
  view?: "DexPaid" | "NewPairs" | "KOTH" | "RaydiumNewPairs";
}) {
  const { tokens, view = "NewPairs" } = props;

  return (
    <div className="c-dashboard">
      <div className="c-dashboard__table">
        <table className={`table table-bordered table-striped table-hover`}>
          <thead>
            <tr>
              <th>Pair Info</th>
              <th>Name</th>
              <th className="cell__aligncenter">Socials</th>
              {view !== "RaydiumNewPairs" && <th className="cell__aligncenter">Dev Tokens</th>}
              {view === "RaydiumNewPairs" && <th className="cell__aligncenter">Top 20 Holding</th>}
              {view === "DexPaid" && <th className="cell__aligncenter">Market Cap</th>}
              {view === "KOTH" && (
                <>
                  <th className="cell__aligncenter">Market Cap</th>
                  <th className="cell__aligncenter">Reply Count</th>
                </>
              )}
              <th className="cell__aligncenter">Created At</th>
              <th>Quick Buy</th>
            </tr>
          </thead>
          <tbody>
            {tokens.length > 0 ? (
              <Fragment>
                {tokens.map((token) => {
                  return (
                    <tr className={token.rowClass} key={token.mintAccount}>
                      <td>
                        <div className="c-table__cell">
                          <div className="c-table__cell__wrap">
                            <div className="c-table__cell--thumb me-2">
                              <img
                                src={token.tokenImageUri ? token.tokenImageUri : LogoP}
                                width={40}
                                height={40}
                                alt={token.tokenName}
                                className="img-fluid"
                              />
                            </div>
                            <div className="c-table__cell">
                              <div className="w-100 mb-0">
                                <span className="u-text-white">{token.tokenSymbol}</span>
                                <span className="mx-1">/</span>
                                <span className="u-text-offwhite">SOL</span>
                              </div>

                              <ClickToCopy address={token.mintAccount} />

                              <ul className="c-header__social ms-0 mt-0.5">
                                {token.twitterUrl ? (
                                  <li className="c-header__social__item">
                                    <a
                                      href={token.twitterUrl}
                                      className="size-16px"
                                      target="_blank"
                                      rel="noreferrer"
                                      data-balloon="Twitter"
                                      data-balloon-pos="up"
                                    >
                                      <Icon name="Twitter" size="16" fill="currentColor" />
                                    </a>
                                  </li>
                                ) : null}
                                {token.telegramUrl ? (
                                  <li className="c-header__social__item">
                                    <a
                                      href={token.telegramUrl}
                                      className="size-16px p-0"
                                      target="_blank"
                                      rel="noreferrer"
                                      data-balloon="Telegram"
                                      data-balloon-pos="up"
                                    >
                                      <Icon name="Telegram" size="16" fill="currentColor" />
                                    </a>
                                  </li>
                                ) : null}
                                {token.websiteUrl ? (
                                  <li className="c-header__social__item">
                                    <a
                                      href={token.websiteUrl}
                                      className="size-16px p1x"
                                      target="_blank"
                                      data-balloon="Website"
                                      data-balloon-pos="up"
                                      rel="noreferrer"
                                    >
                                      <Icon name="Website" size="14" fill="currentColor" />
                                    </a>
                                  </li>
                                ) : null}
                                <li className="c-header__social__item">
                                  <a
                                    href={`${
                                      token.tokenSource === "MOONSHOT" || token.tokenSource === "RAYDIUM"
                                        ? AppConfig.MOONSHOT_URL
                                        : AppConfig.PUMP_URL
                                    }/${token.mintAccount}`}
                                    className="size-16px p-0"
                                    target="_blank"
                                    data-balloon={`${
                                      token.tokenSource === "RAYDIUM"
                                        ? "Raydium"
                                        : token.tokenSource === "MOONSHOT"
                                        ? "Moonshot"
                                        : "Pump.fun"
                                    }`}
                                    data-balloon-pos="up"
                                    rel="noreferrer"
                                  >
                                    <div
                                      className={`c-header__social__source ${
                                        token.tokenSource === "RAYDIUM"
                                          ? "c-header__social__raydium"
                                          : token.tokenSource === "MOONSHOT"
                                          ? "c-header__social__moonshot"
                                          : "c-header__social__pump"
                                      }`}
                                    ></div>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="c-table__cell">
                          <span className="u-text-white text-truncate name">{token.tokenName}</span>
                        </div>
                      </td>
                      <td>
                        <div className="c-table__cell">
                          <div className="c-table__cell__wrap">
                            <div className={`mx-2${!token.twitterUrl ? ` no-pointer` : ``}`}>
                              <div className="c-table__cell u-align-center">
                                <span className="size-14px mb-2">
                                  {token.twitterUrl ? (
                                    <Icon name="Verify" size="14" fill="#22d17a" />
                                  ) : (
                                    <Icon name="Invalid" size="14" fill="#fe4343" />
                                  )}
                                </span>
                                <a
                                  href={token.twitterUrl ?? ""}
                                  className="size-16px p1px u-social"
                                  target="_blank"
                                  rel="noreferrer"
                                  data-balloon="Twitter"
                                  data-balloon-pos="up"
                                >
                                  <Icon name="Twitter" size="16" fill="currentColor" />
                                </a>
                              </div>
                            </div>
                            <div className={`mx-2${!token.telegramUrl ? ` no-pointer` : ``}`}>
                              <div className="c-table__cell u-align-center">
                                <span className="size-14px mb-2">
                                  {token.telegramUrl ? (
                                    <Icon name="Verify" size="14" fill="#22d17a" />
                                  ) : (
                                    <Icon name="Invalid" size="14" fill="#fe4343" />
                                  )}
                                </span>
                                <a
                                  href={token.telegramUrl ?? ""}
                                  className="size-16px p-0 u-social"
                                  target="_blank"
                                  data-balloon="Telegram"
                                  data-balloon-pos="up"
                                  rel="noreferrer"
                                >
                                  <Icon name="Telegram" size="16" fill="currentColor" />
                                </a>
                              </div>
                            </div>
                            <div className={`mx-2${!token.websiteUrl ? ` no-pointer` : ``}`}>
                              <div className="c-table__cell u-align-center">
                                <span className="size-14px mb-2">
                                  {token.websiteUrl ? (
                                    <Icon name="Verify" size="14" fill="#22d17a" />
                                  ) : (
                                    <Icon name="Invalid" size="14" fill="#fe4343" />
                                  )}
                                </span>
                                <a
                                  href={token.websiteUrl ?? ""}
                                  className="size-16px p1x u-social"
                                  target="_blank"
                                  rel="noreferrer"
                                  data-balloon="Website"
                                  data-balloon-pos="up"
                                >
                                  <Icon name="Website" size="16" fill="currentColor" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>

                      {view !== "RaydiumNewPairs" && (
                        <td className="cell__aligncenter">
                          <div className="c-table__cell">
                            <div className="c-table__cell__wrap">
                              <div className="c-table__cell u-align-center">
                                {parseInt(token.devTokensBought) > 0 ? (
                                  <>
                                    <span className="size-14px mb-2">
                                      {token.devTokensPercent < 7 && <Icon name="Verify" size="14" fill="#22d17a" />}
                                      {token.devTokensPercent >= 7 && <Icon name="Invalid" size="14" fill="#fe4343" />}
                                    </span>
                                    <div className="c-table__cell__wrap">
                                      <span className="u-text-white">
                                        {formatNumber(parseInt(token.devTokensBought))}
                                      </span>{" "}
                                      <span
                                        className={`c-table__cell__subtext${
                                          token.devTokensPercent >= 7 ? ` u-text-danger` : ` u-text-success`
                                        } ms-1`}
                                      >
                                        ({token.devTokensPercent}%)
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  "-"
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                      )}

                      {view === "RaydiumNewPairs" && (
                        <td className="cell__aligncenter">
                          <div className="c-table__cell">
                            <div className="c-table__cell__wrap">
                              <div className="c-table__cell u-align-center">
                                <div className="c-table__cell__wrap">
                                  <span className="u-text-white">
                                    {token.raydiumPairInfo.top20 > 0
                                      ? `${token.raydiumPairInfo.top20.toFixed(2)}%`
                                      : "-"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      )}

                      {view === "DexPaid" && (
                        <td className="cell__aligncenter">
                          <div className="c-table__cell">
                            <div className="c-table__cell__wrap">
                              <div className="c-table__cell u-align-center">
                                {parseInt(token.dexPaidInfo.marketCap) > 0 ? (
                                  <div className="c-table__cell__wrap">
                                    <span className="u-text-white">
                                      {formatNumber(parseInt(token.dexPaidInfo.marketCap))}
                                    </span>
                                  </div>
                                ) : (
                                  "-"
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                      )}

                      {view === "KOTH" && (
                        <>
                          <td className="cell__aligncenter">
                            <div className="c-table__cell">
                              <div className="c-table__cell__wrap">
                                <div className="c-table__cell u-align-center">
                                  {parseInt(token.kothInfo.marketCap) > 0 ? (
                                    <div className="c-table__cell__wrap">
                                      <span className="u-text-white">
                                        {formatNumber(parseInt(token.kothInfo.marketCap))}
                                      </span>
                                    </div>
                                  ) : (
                                    "-"
                                  )}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="cell__aligncenter">
                            <div className="c-table__cell">
                              <div className="c-table__cell__wrap">
                                <div className="c-table__cell u-align-center">{token.kothInfo.replyCount}</div>
                              </div>
                            </div>
                          </td>
                        </>
                      )}
                      <td className="cell__aligncenter">
                        <div className="c-table__cell">
                          <div className="c-table__cell__wrap">
                            <span className="size-14px p-0 me-1 u-icon">
                              <Clock className="" />
                            </span>
                            <span className="u-text-white">
                              <TimerRowSection createdValue={token.createdAt} />
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="c-table__cell">
                          <a
                            href={`${AppConfig.PLONKBOT_TG_URL}?start=a_${token.mintAccount}`}
                            target="_blank"
                            className="c-btn-buy px-4"
                            rel="noreferrer"
                          >
                            Buy
                          </a>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </Fragment>
            ) : (
              <tr className="odd text-center">
                <td colSpan={100}>
                  <p className="text-center">No data available</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ListDataTable;
